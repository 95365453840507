import { render, staticRenderFns } from "./BusinessAssets.vue?vue&type=template&id=42ade110&scoped=true&"
import script from "./BusinessAssets.vue?vue&type=script&lang=js&"
export * from "./BusinessAssets.vue?vue&type=script&lang=js&"
import style0 from "./BusinessAssets.vue?vue&type=style&index=0&id=42ade110&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42ade110",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QItem,QItemSection,QIcon,QItemLabel,QChip,QCheckbox,QAvatar});
