import instance, { getUri } from '../index';

const RESOURCE_SHOOT = 'shoot';
const RESOURCE_ACTION = 'action';

export const ContentApi = {
  downloadRaw({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-raw` });
    window.open(uri, 'Download');
  },

  /**
   * Upload Operations
   */
  uploadBrief({ id, blob }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-brief`, blob, {
      headers: {
        'content-type': 'application/octet-stream'
      }
    });
  },
  /**
   * Download Operations
   */
  downloadBrief({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-brief` });
    window.open(uri, 'Download');
  },

  uploadRawsPreview({ id, blob, onUploadProgress }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-raws-preview`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },

  /**
   * Multipart Upload Operations
   */
  initializeMultipartUpload({ id }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/init-multipart-upload`);
  },

  getUploadPartUrl({ id, uploadId, partNumber, key }) {
    return instance.get(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-part-url`, {
      params: { uploadId, partNumber, key }
    });
  },

  completeMultipartUpload({ id, uploadId, key, parts }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/complete-multipart-upload`, { uploadId, key, parts });
  },

  abortMultipartUpload({ id, uploadId, key }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/abort-multipart-upload`, { uploadId, key });
  },

  /**
   * Presigned URL Operations
   */
  getPresignedUrls({ id, files }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/get-presigned-urls`, { files });
  },

  uploadFileWithPresignedUrl(file, presignedUrlData, onUploadProgress) {
    return instance.put(presignedUrlData.presignedUrl, file, {
      headers: {
        'Content-Type': file.type
      },
      onUploadProgress
    });
  },

  uploadFilesWithPresignedUrls(files, presignedUrlsData, onTotalProgress) {
    let totalLoaded = 0;
    const totalSize = files.reduce((sum, file) => sum + file.size, 0);

    const uploadPromises = files.map((file, index) => {
      return this.uploadFileWithPresignedUrl(file, presignedUrlsData[index], progressEvent => {
        const newLoaded = progressEvent.loaded - progressEvent.total * (progressEvent.loaded / progressEvent.total);
        totalLoaded += newLoaded;
        const totalProgress = (totalLoaded / totalSize) * 100;
        onTotalProgress(totalProgress);
      });
    });

    return Promise.all(uploadPromises);
  },

  /**
   * Image and Video Operations
   */
  createImagesBatch({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/create-images-batch`, payload);
  },

  createVideosBatch({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/create-videos-batch`, payload);
  },

  updateImageStatus({ id, payload }) {
    return instance.post(`/image/${id}/action/update-status`, payload);
  },

  updateRawImageStatus({ id, payload }) {
    return instance.post(`/raw/${id}/action/update-status`, payload);
  },

  bulkApproveImages({ payload }) {
    return instance.post('/image/action/bulk-approve', payload);
  },

  approveRemainingImages({ payload }) {
    return instance.post('/image/action/approve-remaining', payload);
  },

  bulkSelectRaws({ payload }) {
    return instance.post('/raw/action/bulk-select', payload);
  },

  updatePreview({ id, payload }) {
    return instance.post(`/shoot/${id}/action/update-preview`, payload);
  },

  updateRawsPreview({ id, payload }) {
    return instance.post(`/shoot/${id}/action/update-raws-preview`, payload);
  },

  /**
   * Image Search and Format Operations
   */
  getImages({ filters = {}, pagination = { limit: 20, cursor: null } } = {}) {
    return instance
      .post('/images/search', {
        filters,
        pagination
      })
      .then(response => ({
        images: response.data.images,
        pagination: response.data.pagination
      }));
  },

  exportImages({ imageIds, formatOptions }) {
    return instance
      .post('/images/format', {
        imageIds,
        formatOptions: {
          width: formatOptions.width,
          height: formatOptions.height,
          fit: formatOptions.fit,
          format: formatOptions.format,
          quality: formatOptions.quality,
          preserveAspectRatio: formatOptions.preserveAspectRatio,
          watermark: formatOptions.watermark
        }
      })
      .then(response => response.data.images);
  },

  /**
   * Download Job Operations
   */
  enqueueDownloadJob({ id }) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/enqueue-download-job`).then(response => {
      return {
        shootId: response.data?.shootId,
        jobId: response.data?.jobId,
        status: response.data?.status || 'pending'
      };
    });
  },

  getDownloadJobStatus({ id }) {
    return instance.get(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/enqueue-download-job/status`).then(response => {
      return {
        status: response.data?.status || 'pending',
        lastUpdated: new Date(response.data?.lastUpdated),
        progress: response.data?.progress,
        message: response.data?.message,
        startedAt: response.data?.startedAt ? new Date(response.data?.startedAt) : null,
        shootId: response.data?.shootId,
        downloadUrl: response.data?.downloadUrl || null
      };
    });
  }
};
