<template>
  <div class="container">
    <div class="content-container">
      <p class="section-label">Connected business assets</p>
      <div class="cards-grid">
        <!-- Facebook Pages Card -->
        <q-card
          v-for="page in metaIntegration.pages"
          :key="page.id"
          :class="{ selected: isAccountSelected(page.id), unpublished: !page.isPublished }"
          @click="toggleAccount(page, 'facebook')"
          flat
          bordered
        >
          <!-- Same Facebook card content as before -->
          <q-item>
            <q-item-section avatar>
              <q-icon name="fab fa-facebook" class="facebook-icon" size="lg" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="row items-center">
                {{ page.name }}
                <q-icon v-if="page.verificationStatus === 'verified'" name="check_circle" color="primary" size="sm" class="q-ml-sm" />
              </q-item-label>
              <q-item-label caption>{{ page.category }}</q-item-label>
              <div class="row items-center q-gutter-x-sm q-mt-xs">
                <q-chip size="sm">{{ formatNumber(page.followersCount) }} followers</q-chip>
                <q-chip v-if="!page.isPublished" size="sm">Unpublished</q-chip>
              </div>
            </q-item-section>
            <q-item-section side>
              <q-checkbox v-model="selectedAccounts" :val="page" color="primary" />
            </q-item-section>
          </q-item>
        </q-card>

        <!-- Instagram Accounts Card -->
        <q-card
          v-for="account in metaIntegration.instagramAccounts"
          :key="account.id"
          :class="{ selected: isAccountSelected(account.id), unpublished: !account.isPublished }"
          @click="toggleAccount(account)"
          flat
          bordered
        >
          <q-item>
            <q-item-section avatar>
              <q-avatar>
                <q-icon name="fab fa-instagram" size="lg" class="instagram-icon" />
              </q-avatar>
            </q-item-section>

            <q-item-section>
              <q-item-label>{{ account.username }}</q-item-label>
              <q-item-label caption>{{ account.connectedPageName }}</q-item-label>
              <div class="row items-center q-gutter-x-sm q-mt-xs">
                <q-chip size="sm">{{ formatNumber(account.followedByCount) }} followers</q-chip>
                <q-chip v-if="account.isPrivate" size="sm" color="warning">Private</q-chip>
                <q-chip v-if="!account.isPublished" size="sm">Unpublished</q-chip>
              </div>
            </q-item-section>

            <q-item-section side>
              <q-checkbox v-model="selectedAccounts" :val="account" color="primary" />
            </q-item-section>
          </q-item>
        </q-card>
      </div>

      <div class="post-content-container q-mt-md">
        <p class="section-label">Post content</p>
        <q-input
          v-model="rawPostContent"
          type="textarea"
          filled
          bgColor="white"
          class="post-textarea"
          placeholder="Write your post content here..."
          :error="hasError"
          :error-message="errorMessage"
          @input="validateContent"
          counter
        >
        </q-input>

        <!-- Hashtags Section -->
        <div class="tags-section">
          <p class="section-label">Hashtags</p>
          <div class="row items-center">
            <q-input bgColor="white" v-model="newHashtag" filled class="tag-input" placeholder="Add hashtag..." @keyup.enter="addHashtag">
              <template v-slot:prepend>
                <q-icon name="tag" size="sm" />
              </template>
            </q-input>
          </div>
          <div v-if="hashtags.length > 0">
            <q-chip v-for="tag in hashtags" :key="tag" removable @remove="removeHashtag(tag)" color="primary" text-color="white" class="tag-chip"> #{{ tag.replace(/^#/, '') }} </q-chip>
          </div>
        </div>
      </div>
    </div>
    <div class="preview-container">
      <!-- <q-btn-toggle
        bgColor="white"
        v-model="previewType"
        flat
        :options="[
          { label: 'Facebook', value: 'facebook', icon: 'fab fa-facebook' },
          { label: 'Instagram', value: 'instagram', icon: 'fab fa-instagram' }
        ]"
        class="preview-toggle"
      /> -->

      <p class="section-label">Preview post</p>
      <!-- Facebook Preview -->
      <div v-if="previewType === 'facebook'" class="facebook-preview">
        <div class="preview-card">
          <div class="post-header">
            <q-avatar size="40px" class="custom-avatar">
              <template v-if="selectedPageAvatar">
                <img :src="selectedPageAvatar" alt="Page avatar" />
              </template>
              <template v-else>
                <div class="initials-avatar">FP</div>
              </template>
            </q-avatar>
            <div class="post-meta">
              <div class="post-page-name">{{ selectedPageName }}</div>
              <div class="post-time">{{ formattedDateTime }} · <q-icon name="public" size="xs" /></div>
            </div>
            <div class="post-actions-right" style="margin-left: auto">
              <q-btn flat round dense icon="more_horiz" size="sm" />
              <q-btn flat round dense icon="close" size="sm" />
            </div>
          </div>

          <div class="post-content">{{ finalContent }}</div>
          <div class="media-preview-container">
            <img
              v-lazy="{
                src: previewUrl,
                loading: placeholderUrl
              }"
              class="media-preview"
            />
          </div>

          <div class="post-actions">
            <div class="action-item"><q-icon name="thumb_up_off_alt" /> Like</div>
            <div class="action-item"><q-icon name="chat_bubble_outline" /> Comment</div>
            <div class="action-item"><q-icon name="share" /> Share</div>
          </div>
        </div>
      </div>

      <!-- Instagram Preview -->
      <div v-else class="instagram-preview">
        <div class="preview-card">
          <div class="post-header">
            <q-avatar size="32px">
              <q-icon name="fab fa-instagram" class="instagram-icon" size="lg" />
            </q-avatar>
            <q-icon name="more_horiz" class="more-icon" />
          </div>

          <div v-if="previewUrl" class="post-media instagram-media">
            <img :src="mediaUrl" :alt="'Post media'" class="media-preview" />
          </div>

          <div class="instagram-actions">
            <div class="left-actions">
              <q-icon name="favorite_border" size="md" />
              <q-icon name="chat_bubble_outline" size="md" />
              <q-icon name="send" size="md" />
            </div>
            <q-icon name="bookmark_border" size="md" />
          </div>

          <div class="post-content">
            {{ finalContent }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';

export default {
  name: 'MetaShareDialog',
  emits: ['hide', 'share'],
  props: {
    metaIntegration: {
      type: Object,
      required: true
    },
    mediaUrl: {
      type: String,
      default: null
    },
    previewUrl: {
      type: String,
      default: null
    },
    placeholderUrl: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const rawPostContent = ref(
      `Creating moments that matter. ✨

Discover what makes each day special. Whether it's the perfect morning coffee, a cozy corner in your home, or that breathtaking sunset view - we love seeing your everyday inspirations.

Share your story with us in the comments below! 👇
`
    );
    const selectedAccounts = ref([]);
    const isSharing = ref(false);
    const hasError = ref(false);
    const errorMessage = ref('');
    const maxLength = 2200;
    const previewType = ref('facebook');

    const hashtags = ref([]);
    const newHashtag = ref('');

    // Computed final content
    const finalContent = computed(() => {
      const hashtagsStr = hashtags.value.length ? '\n\n' + hashtags.value.map(tag => `#${tag.replace(/^#/, '')}`).join(' ') : '';
      return `${rawPostContent.value}${hashtagsStr}`;
    });

    const postContent = computed({
      get: () => finalContent.value,
      set: val => (rawPostContent.value = val)
    });

    const isOverLimit = computed(() => finalContent.value.length > maxLength);

    const isValid = computed(() => {
      return finalContent.value.trim().length > 0 && finalContent.value.length <= maxLength && selectedAccounts.value.length > 0;
    });

    function validateContent() {
      if (finalContent.value.trim().length === 0) {
        hasError.value = true;
        errorMessage.value = 'Post content cannot be empty';
      } else if (finalContent.value.length > maxLength) {
        hasError.value = true;
        errorMessage.value = `Content exceeds maximum length of ${maxLength} characters`;
      } else {
        hasError.value = false;
        errorMessage.value = '';
      }
    }

    // Hashtag functions
    function addHashtag() {
      if (!newHashtag.value.trim()) return;
      const tag = newHashtag.value.trim().replace(/^#/, '').replace(/\s+/g, '');
      if (tag && !hashtags.value.includes(tag)) {
        hashtags.value.push(tag);
      }
      newHashtag.value = '';
      validateContent();
    }

    function removeHashtag(tag) {
      hashtags.value = hashtags.value.filter(t => t !== tag);
      validateContent();
    }

    function isAccountSelected(id) {
      return selectedAccounts.value.some(selected => selected.id === id);
    }

    // Modified toggleAccount function
    function toggleAccount(account) {
      const index = selectedAccounts.value.findIndex(selected => selected.id === account.id);
      if (index === -1) {
        selectedAccounts.value.push(account);
      } else {
        selectedAccounts.value.splice(index, 1);
      }
    }

    function formatNumber(num) {
      return num >= 1e6 ? (num / 1e6).toFixed(1) + 'M' : num >= 1e3 ? (num / 1e3).toFixed(1) + 'K' : num.toString();
    }

    async function handleShare() {
      if (!isValid.value) return;
      isSharing.value = true;
      try {
        await emit('share', {
          accounts: selectedAccounts.value,
          content: finalContent.value,
          media: props.mediaUrl
        });
        emit('hide');
      } catch (error) {
        console.error('Error sharing to Meta:', error);
      } finally {
        isSharing.value = false;
      }
    }

    // Watchers
    watch([rawPostContent, hashtags], () => {
      validateContent();
    });

    const formattedDateTime = computed(() => {
      const now = new Date();
      return (
        now.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric'
        }) +
        ' at ' +
        now.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        })
      );
    });

    const selectedPageName = computed(() => {
      const selectedPage = selectedAccounts.value.find(account => account.name !== null);
      return selectedPage?.name || 'Facebook Page';
    });

    const selectedPageAvatar = computed(() => {
      const selectedPage = selectedAccounts.value.find(account => account.profilePic !== null);
      return selectedPage?.profilePic || null;
    });

    return {
      rawPostContent,
      postContent,
      selectedAccounts,
      isSharing,
      isValid,
      isAccountSelected,
      toggleAccount,
      handleShare,
      formatNumber,
      hasError,
      errorMessage,
      isOverLimit,
      validateContent,
      hashtags,
      newHashtag,
      addHashtag,
      removeHashtag,
      finalContent,
      maxLength,
      previewType,
      formattedDateTime,
      selectedPageAvatar,
      selectedPageName
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 24px !important;
  background-color: var(--main-bg-color);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.q-card {
  cursor: pointer;
  transition: all 0.2s ease;
  border: 0;
  border-radius: 0 !important;

  &:hover {
    border-bottom: 0.5px solid var(--main-text-color) !important;
  }

  &.selected {
    border-bottom: 1px solid var(--main-btn-color) !important;
  }
}

.section-label {
  @include inter-font($size: 16px, $bolded: true, $color: var(--main-text-color));
  margin-bottom: 12px;
}

.instagram-icon {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.facebook-icon {
  background: linear-gradient(45deg, #0062e0 0%, #19afff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.post-content-container {
  margin-top: 24px;

  .content-header {
    @include inter-font($size: 16px, $bolded: true, $color: var(--main-text-color));
    margin-bottom: 12px;
  }

  .post-textarea {
    width: 100%;
    margin: 0 !important;
  }

  .character-count {
    text-align: right;
    background-color: red;
    margin: 0;
    font-size: 12px;
    color: var(--secondary-text-color);

    &.text-negative {
      color: var(--negative-color);
    }
  }
}

.tags-section {
  .tag-input {
    width: 100%;
  }

  .tag-chip {
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.9;
    }
  }
}

.preview-container {
  padding-left: 24px;
  height: 100%;
  overflow-y: auto;

  .preview-card {
    background: white;
  }

  .preview-toggle {
    background-color: white;
    margin-bottom: 8px;
  }

  .post-header {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid var(--border-color);

    .post-meta {
      margin-left: 12px;

      .page-name {
        font-weight: 600;
        font-size: 14px;
      }

      .post-time {
        font-size: 12px;
        color: var(--secondary-text-color);
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .username {
      margin-left: 12px;
      font-weight: 600;
      font-size: 14px;
      flex-grow: 1;
    }

    .more-icon {
      color: var(--secondary-text-color);
    }
  }

  .post-content {
    padding: 16px;
    font-size: 14px;
    white-space: pre-wrap;

    .username {
      font-weight: 600;
      margin-right: 8px;
    }
  }

  .media-preview-container {
    position: relative;
    width: 100%;
    padding-top: 52.36%; /* 1.91:1 aspect ratio (1/1.91 = 0.5236) */
    overflow: hidden;
  }

  .media-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .post-actions {
    display: flex;
    padding: 4px 16px;
    border-top: 1px solid var(--border-color);

    .action-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 8px;
      color: var(--secondary-text-color);
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: var(--hover-bg-color);
      }
    }
  }

  .instagram-actions {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;

    .left-actions {
      display: flex;
      gap: 16px;
    }

    .q-icon {
      cursor: pointer;
      color: var(--main-text-color);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.custom-avatar {
  .initials-avatar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color, #1976d2);
    color: white;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
