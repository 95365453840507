import instance from '../index';
const RESOURCE_FACEBOOK = 'facebook';

export const IntegrationsApi = {
  /**
   * Connect to Facebook Business using authorization code
   * @param {string} code - Authorization code from Facebook Business SDK
   * @returns {Promise<Object>} Connection details including business and account data
   * @property {boolean} success - Operation success status
   * @property {Object} data - Integration data
   * @property {string} data.businessId - Facebook Business ID
   * @property {string} data.businessName - Business name
   * @property {Array} data.pages - Connected Facebook pages
   * @property {Array} data.instagramAccounts - Connected Instagram accounts
   */
  connectFacebook(code) {
    return instance.post(`/${RESOURCE_FACEBOOK}/connect`, {
      code
    });
  },

  /**
   * Check Facebook Business integration status
   * @returns {Promise<Object>} Integration status and connected accounts
   * @property {boolean} success - Operation success status
   * @property {boolean} connected - Whether user has active Facebook integration
   * @property {string} [reason] - Reason if not connected ('NO_INTEGRATION' or 'TOKEN_EXPIRED')
   * @property {Object} [integration] - Integration details if connected
   * @property {string} [integration.businessId] - Facebook Business ID
   * @property {string} [integration.businessName] - Business name
   * @property {Array} [integration.pages] - Connected Facebook pages
   * @property {Array} [integration.instagramAccounts] - Connected Instagram accounts
   * @property {string} [integration.connectedAt] - Connection timestamp
   */
  getFacebookStatus() {
    return instance.get(`/${RESOURCE_FACEBOOK}/status`);
  },

  /**
   * Disconnect Facebook Business integration
   * @returns {Promise<Object>} Disconnection result
   * @property {boolean} success - Operation success status
   */
  disconnectFacebook() {
    return instance.post(`/${RESOURCE_FACEBOOK}/disconnect`);
  }
};

/**
 * Example response shapes:
 *
 * Connect success:
 * {
 *   success: true,
 *   data: {
 *     businessId: "482995101478133",
 *     businessName: "Flashy biz",
 *     pages: [{
 *       id: "521462504380732",
 *       name: "Flashy integration dev",
 *       category: "News & media website",
 *       tasks: ["ADVERTISE", "ANALYZE", ...],
 *       isPublished: true,
 *       categoryList: [{ id: "2709", name: "News & media website" }],
 *       followersCount: 0,
 *       verificationStatus: "not_verified"
 *     }],
 *     instagramAccounts: [{
 *       id: "8704963292960057",
 *       username: "flashy772",
 *       followCount: 0,
 *       followedByCount: 0,
 *       hasProfilePicture: true,
 *       isPrivate: false,
 *       isPublished: false,
 *       mediaCount: 0,
 *       profilePic: "...",
 *       connectedPageId: "511604588703130",
 *       connectedPageName: "Flashy fanpage"
 *     }]
 *   }
 * }
 *
 * Status response:
 * {
 *   success: true,
 *   connected: true,
 *   integration: {
 *     businessId: "482995101478133",
 *     businessName: "Flashy biz",
 *     pages: [...],
 *     instagramAccounts: [...],
 *     connectedAt: "2024-03-19T10:30:00Z"
 *   }
 * }
 *
 * Or if not connected:
 * {
 *   success: true,
 *   connected: false,
 *   reason: "NO_INTEGRATION"
 * }
 */

export default IntegrationsApi;
