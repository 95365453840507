import instance, { getUri } from '../index';
import store from '../../store/index';

// Utils
import { CANCELED } from '@utils/shootsHelpers';

const RESOURCE_ACTION = 'action';
const RESOURCE_SHOOT = 'shoot';
const RESOURCE_SHOOTS = 'shoots';
const RESOURCE_SEARCH = 'search';

export const ShootsApi = {
  /**
   * Assign photographer
   */
  assignPhotographer({ shoot_id, photographer_id }) {
    return instance.post(`${RESOURCE_SHOOT}/${shoot_id}/${RESOURCE_ACTION}/assign-photographer`, {
      photographer_id
    });
  },
  /**
   * Method used to cancel a shoot. It will call the updateStatusApi with 'CANCELED' as status
   */
  cancel(id) {
    return this.updateStatus({
      id,
      payload: {
        targetStatus: CANCELED
      }
    });
  },
  /**
   * Api call to create a new shoot
   */
  createShoot({ payload }) {
    const payloadClone = JSON.parse(JSON.stringify(payload));
    const type = payloadClone.type;

    if (type === 'custom') {
      payloadClone.is_payed = true;
    } else if (type === 'express') {
      let price = 0;
      let revenue = 0;

      payloadClone.packages.forEach(element => {
        price += Number(element.price);
        revenue += Number(element.photographer_revenue);

        delete element.duration;
        delete element.price;
        delete element.photographer_revenue;
        delete element.picture_number;
        delete element.name;
        delete element.service_id;
      });

      delete payloadClone.total_price;
      delete payloadClone.total_revenue;

      if (store.getters['user/isAdmin']) {
        payloadClone.total_price = price;
        payloadClone.total_revenue = revenue;

        payloadClone.photographer_id = null;
      }
    }

    payloadClone.type = type;

    return instance.post(`/${RESOURCE_SHOOT}`, payloadClone);
  },
  /**
   * Api call to download shoot brief
   */
  downloadBrief({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-brief` });
    window.open(uri, 'Download');
  },
  /**
   * Api call to download flashy edited photo
   */
  downloadProcessed({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-processed` });
    window.open(uri, 'Download');
  },

  downloadProcessedLambda({ id }) {
    return instance.get(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-processed-lambda`).then(response => {
      if (response.data && response.data.downloadUrl) {
        window.open(response.data.downloadUrl, '_blank');
      } else {
        throw new Error('Download URL not found in response');
      }
    });
  },

  getDownloadProcessedUri(id) {
    return getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-processed` });
  },
  /**
   * Api call to download flashy edited photo
   */
  downloadRaw({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-raw` });
    window.open(uri, 'Download');
  },
  /**
   * Api call to edit shoot
   */
  editShoot({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/edit/${id}`, payload);
  },
  updatePreview({ id, payload }) {
    return instance.post(`/shoot/${id}/action/update-preview`, payload);
  },
  updateRawsPreview({ id, payload }) {
    return instance.post(`/shoot/${id}/action/update-raws-preview`, payload);
  },
  /**
   * Get shoots search filters
   */
  getFilters() {
    return instance.get(`/${RESOURCE_SHOOT}/${RESOURCE_ACTION}/get-shoots-filters`);
  },
  /**
   * Api call to schedule shoot
   */
  scheduleShoot({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/schedule/${id}`, payload);
  },
  searchShoots(payload) {
    return instance.post(`/${RESOURCE_SHOOTS}/${RESOURCE_SEARCH}`, payload);
  },
  /**
   * Api call to remove a photographer from a given shoot
   * @param {Number} id shoot id
   * @returns Api call response
   */
  unassignPhotographer(id) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/unassign-photographer`);
  },
  updateStatus({ id, payload }) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/update-status`, payload);
  },
  updateImageStatus({ id, payload }) {
    return instance.post(`/image/${id}/action/update-status`, payload);
  },
  updateRawImageStatus({ id, payload }) {
    return instance.post(`/raw/${id}/action/update-status`, payload);
  },
  bulkApproveImages({ payload }) {
    return instance.post('/image/action/bulk-approve', payload);
  },
  approveRemainingImages({ payload }) {
    return instance.post('/image/action/approve-remaining', payload);
  },

  bulkSelectRaws({ payload }) {
    return instance.post('/raw/action/bulk-select', payload);
  },
  /**
   * Api call to updload the shoot raw photos (photographer uploading shoots)
   */
  uploadBrief({ id, blob }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-brief`, blob, {
      headers: {
        'content-type': 'application/octet-stream'
      }
    });
  },
  /**
   * Upload processed photos
   */
  uploadProcessedOld({ id, blob, onUploadProgress }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-processed`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },

  /**
   * Upload processed photos
   */

  uploadProcessed({ id, files, onUploadProgress }) {
    const formData = new FormData();

    files.forEach(file => {
      // Use the full path (including folders) as the key
      const filePath = file.webkitRelativePath || file.name;
      formData.append('files', file, filePath);
    });

    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-processed`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  },
  uploadRawsPreview({ id, blob, onUploadProgress }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-raws-preview`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },
  /**
   * Initialize a multipart upload for RAW content
   * @param {Object} options
   * @param {string|number} options.id - Shoot ID
   * @returns {Promise<{uploadId: string, key: string}>}
   */
  initializeMultipartUpload({ id }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/init-multipart-upload`);
  },

  /**
   * Get a presigned URL for uploading a part
   * @param {Object} options
   * @param {string|number} options.id - Shoot ID
   * @param {string} options.uploadId - Upload ID from initialization
   * @param {number} options.partNumber - Part number (1 to 10000)
   * @param {string} options.key - Object key from initialization
   * @returns {Promise<{presignedUrl: string}>}
   */
  getUploadPartUrl({ id, uploadId, partNumber, key }) {
    return instance.get(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-part-url`, {
      params: { uploadId, partNumber, key }
    });
  },

  /**
   * Complete a multipart upload
   * @param {Object} options
   * @param {string|number} options.id - Shoot ID
   * @param {string} options.uploadId - Upload ID
   * @param {string} options.key - Object key
   * @param {Array<{PartNumber: number, ETag: string}>} options.parts - Array of uploaded parts
   * @returns {Promise<{location: string, bucket: string, key: string}>}
   */
  completeMultipartUpload({ id, uploadId, key, parts }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/complete-multipart-upload`, { uploadId, key, parts });
  },

  /**
   * Abort a multipart upload
   * @param {Object} options
   * @param {string|number} options.id - Shoot ID
   * @param {string} options.uploadId - Upload ID
   * @param {string} options.key - Object key
   * @returns {Promise<{message: string}>}
   */
  abortMultipartUpload({ id, uploadId, key }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/abort-multipart-upload`, { uploadId, key });
  },

  getShootById({ shoot_id }) {
    return instance.get(`/${RESOURCE_SHOOT}/${shoot_id}`);
  },
  /**
   * Get presigned URLs for uploading processed photos
   * @param {string} id - The shoot ID
   * @param {Array} files - Array of file objects to upload
   * @returns {Promise} - Promise resolving to the presigned URLs
   */
  getPresignedUrls({ id, files }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/get-presigned-urls`, { files });
  },
  /**
   * Upload a file using a presigned PUT URL
   * @param {File} file - The file to upload
   * @param {Object} presignedUrlData - The presigned URL data
   * @param {Function} onUploadProgress - Progress callback function
   * @returns {Promise} - Promise resolving to the upload result
   */
  uploadFileWithPresignedUrl(file, presignedUrlData, onUploadProgress) {
    return instance.put(presignedUrlData.presignedUrl, file, {
      headers: {
        'Content-Type': file.type
      },
      onUploadProgress
    });
  },

  /**
   * Upload multiple files using presigned PUT URLs
   * @param {Array} files - Array of files to upload
   * @param {Array} presignedUrlsData - Array of presigned URL data
   * @param {Function} onTotalProgress - Callback for total upload progress
   * @returns {Promise} - Promise resolving when all uploads are complete
   */
  uploadFilesWithPresignedUrls(files, presignedUrlsData, onTotalProgress) {
    let totalLoaded = 0;
    const totalSize = files.reduce((sum, file) => sum + file.size, 0);

    const uploadPromises = files.map((file, index) => {
      return this.uploadFileWithPresignedUrl(file, presignedUrlsData[index], progressEvent => {
        const newLoaded = progressEvent.loaded - progressEvent.total * (progressEvent.loaded / progressEvent.total);
        totalLoaded += newLoaded;
        const totalProgress = (totalLoaded / totalSize) * 100;
        onTotalProgress(totalProgress);
      });
    });

    return Promise.all(uploadPromises);
  },

  /**
   * Batch creates or updates images
   * @param {string} id - The shoot ID
   * @param {Object} payload - The payload containing userData and images
   * @returns {Promise} - Promise resolving to the created images
   */
  createImagesBatch({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/create-images-batch`, payload);
  },

  /**
   * Batch creates or updates videos
   * @param {string} id - The shoot ID
   * @param {Object} payload - The payload containing userData and videos
   * @returns {Promise} - Promise resolving to the created videos
   */
  createVideosBatch({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/create-videos-batch`, payload);
  },
  /**
   * Enqueues a download job for a shoot's processed content
   * @param {Object} params - The parameters for the download job
   * @param {string} params.id - The ID of the shoot
   * @param {string} params.email - The email address to send the download link to
   * @returns {Promise<Object>} - Promise resolving to the job details
   * @property {string} shootId - The ID of the shoot
   * @property {string} jobId - The ID of the created job
   * @property {string} email - The email address for the download link
   * @property {string} status - The status of the job (default: 'pending')
   */
  enqueueDownloadJob({ id }) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/enqueue-download-job`).then(response => {
      // Return the formatted response data
      return {
        shootId: response.data?.shootId,
        jobId: response.data?.jobId,
        status: response.data?.status || 'pending'
      };
    });
  },
  /**
   * Get the status of an enqueued download job
   * @param {Object} params - The parameters for getting the job status
   * @param {string} params.id - The ID of the shoot
   * @returns {Promise<Object>} - Promise resolving to the job status details
   * @property {string} status - The current status of the job
   * @property {Date} lastUpdated - When the status was last updated
   * @property {number} progress - Download progress percentage (optional)
   * @property {string} message - Status message (optional)
   * @property {Date} startedAt - When the job started (optional)
   * @property {string} shootId - The ID of the shoot (optional)
   * @property {string} downloadUrl - The presigned download URL (optional)
   */
  getDownloadJobStatus({ id }) {
    return instance.get(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/enqueue-download-job/status`).then(response => {
      // Return the formatted response data
      return {
        status: response.data?.status || 'pending',
        lastUpdated: new Date(response.data?.lastUpdated),
        progress: response.data?.progress,
        message: response.data?.message,
        startedAt: response.data?.startedAt ? new Date(response.data?.startedAt) : null,
        shootId: response.data?.shootId,
        downloadUrl: response.data?.downloadUrl || null
      };
    });
  },

  /**
   * Get images with filtering, dual search and cursor-based pagination
   * @param {Object} params - Search parameters
   * @param {Object} [params.filters] - Filter options
   * @param {Object} [params.filters.date] - Date range filter
   * @param {Date} [params.filters.date.from] - Start date
   * @param {Date} [params.filters.date.to] - End date
   * @param {number} [params.filters.clientId] - End date
   * @param {Array<string>} [params.filters.services] - Service categories
   * @param {Array<string>} [params.filters.aspectRatios] - Aspect ratio filters (['square','landscape','portrait'])
   * @param {string} [params.filters.metadataSearchQuery] - Business context search (shoot/outlet details)
   * @param {string} [params.filters.visualSearchQuery] - Visual similarity search
   * @param {Object} [params.pagination] - Pagination options
   * @param {number} [params.pagination.limit=20] - Items per page
   * @param {string} [params.pagination.cursor] - Pagination cursor
   * @returns {Promise<{images: Array, pagination: Object}>} Images and pagination data
   */
  getImages({ filters = {}, pagination = { limit: 20, cursor: null } } = {}) {
    return instance
      .post('/images/search', {
        filters,
        pagination
      })
      .then(response => ({
        images: response.data.images,
        pagination: response.data.pagination
      }));
  },
  /**
   * Format images with custom sizing, transformations and watermark
   * @param {Object} params - Formatting parameters
   * @param {number[]} params.imageIds - Array of image IDs to process
   * @param {Object} params.formatOptions - Image transformation options
   * @param {number} [params.formatOptions.width] - Desired width in pixels
   * @param {number} [params.formatOptions.height] - Desired height in pixels
   * @param {string} [params.formatOptions.fit=fit-in] - Fit mode: 'fit-in', 'adaptive', 'cover'
   * @param {string} [params.formatOptions.format=jpeg] - Output format: 'jpeg', 'webp'
   * @param {number} [params.formatOptions.quality=85] - Image quality (1-100)
   * @param {boolean} [params.formatOptions.preserveAspectRatio=true] - Maintain aspect ratio during resize
   * @param {Object} [params.formatOptions.watermark] - Watermark configuration
   * @param {string} params.formatOptions.watermark.image - Base64 watermark image string
   * @param {string} params.formatOptions.watermark.x - X position (e.g. -10p, center)
   * @param {string} params.formatOptions.watermark.y - Y position (e.g. 10p, center)
   * @param {number} params.formatOptions.watermark.alpha - Watermark opacity (0-100)
   * @param {string} params.formatOptions.watermark.w_ratio - Width as percentage of image
   * @param {string} params.formatOptions.watermark.h_ratio - Height ratio
   * @returns {Promise<Array<{id: number, url: string, error?: string}>>} Formatted image URLs
   */
  exportImages({ imageIds, formatOptions }) {
    return instance
      .post('/images/format', {
        imageIds,
        formatOptions: {
          width: formatOptions.width,
          height: formatOptions.height,
          fit: formatOptions.fit,
          format: formatOptions.format,
          quality: formatOptions.quality,
          preserveAspectRatio: formatOptions.preserveAspectRatio,
          watermark: formatOptions.watermark
        }
      })
      .then(response => response.data.images);
  }
};
