<template>
  <div class="q-pa-md">
    <div class="stats-bar">
      <q-chip icon="o_check_box" square class="stats-badge">{{ selectedImages.length }}</q-chip>
      <q-chip icon="o_image" square class="stats-badge">{{ images.length }} / {{ pagination.total }} </q-chip>
      <q-chip icon="o_select_all" square class="stats-badge" style="margin-right: auto" clickable @click="toggleSelectAll">
        {{ selectedImages.length === images.length ? 'Deselect All' : 'Select All' }}
      </q-chip>

      <q-btn
        :disabled="selectedImages.length === 0"
        color="primary"
        textColor="#151515"
        icon="o_cloud_download"
        :label="`Download images`"
        no-caps
        @click="handleExport"
        :loading="isExporting"
        :percentage="exportProgress"
        style="margin-right: 12px"
      />
    </div>
    <div class="images-grid">
      <div v-for="image in images" :key="image.id" class="grid-item" :class="[getImageClass(image), { selected: isSelected(image) }]" @click="toggleSelection(image)">
        <div class="selection-overlay" v-if="isSelected(image)">
          <q-icon name="check_circle" size="32px" color="white" />
        </div>
        <q-img :src="image.preview_url" :placeholder-src="image.placeholder_url" class="grid-image" fit="cover" />
        <div class="image-overlay">
          <div class="overlay-info">
            <q-chip icon="o_camera" text-color="grey-6" square size="xs" class="overlay-chip">{{ image.shoot_id }}</q-chip>
            <q-chip icon="o_image" text-color="grey-6" v-if="image.width && image.height" square size="xs" class="overlay-chip">
              {{ `${image.width}x${image.height}` }}
            </q-chip>
          </div>
          <div class="action-buttons">
            <q-btn color="black" flat round class="action-btn" @click.stop="handleDownload(image)" :loading="downloadingStates[image.id]">
              <q-icon name="o_download" size="18px" />
              <q-tooltip>Download Image</q-tooltip>
            </q-btn>

            <q-btn color="black" flat round class="action-btn" @click.stop="handleCopyLink(image.url)">
              <q-icon name="o_link" size="18px" />
              <q-tooltip>Copy Link</q-tooltip>
            </q-btn>

            <!-- <q-btn color="black" flat round class="action-btn" @click.stop="shareToMeta(image)">
              <q-icon name="share" size="18px" />
              <q-tooltip>Share on Meta</q-tooltip>
            </q-btn> -->
            <q-btn color="black" flat round class="action-btn" @click.stop="openLightbox(images.indexOf(image))">
              <q-icon name="o_fullscreen" size="18px" />
              <q-tooltip>Open in Lightbox</q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-if="showShareDialog" v-model="showShareDialog" @hide="selectedShareImage = null" full-width full-height>
      <MetaShareDialog :metaIntegration="metaIntegration" :mediaUrl="selectedShareImage.url" :previewUrl="selectedShareImage.preview_url" :placeholderUrl="selectedShareImage.placeholder_url" />
    </q-dialog>
    <ImageFormatDialog v-if="showExportDialog" v-model="showExportDialog" @format="exportImages" />
    <GalleryLightbox v-if="lightboxOpen" :images="images" :startIndex="lightboxStartIndex" @close="lightboxOpen = false" />
  </div>
</template>

<script>
import { ShootsApi } from '@api/index';
import MetaShareDialog from './MetaShareDialog.vue';
import ImageFormatDialog from './ImageFormatDialog.vue';
import GalleryLightbox from './GalleryLightbox.vue';

const ASPECT_RATIO_THRESHOLD = 1.5;
const TALL_RATIO_THRESHOLD = 0.7;

export default {
  name: 'ImagesGrid',
  components: { MetaShareDialog, ImageFormatDialog, GalleryLightbox },
  computed: {
    showShareDialog: {
      get() {
        return !!this.selectedShareImage;
      },
      set(value) {
        if (!value) {
          this.selectedShareImage = null;
        }
      }
    }
  },

  props: {
    images: {
      type: Array,
      required: true,
      default: () => []
    },
    pagination: {},
    metaIntegration: {
      type: Object,
      required: false,
      default: () => {}
    },
    isMetaConnected: {
      type: Boolean,
      required: false,
      default: false
    },
    visualSearchValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      downloadingStates: {},
      selectedImages: [],
      downloadProgress: 0,
      showExportDialog: false,
      isExporting: false,
      exportProgress: 0,
      selectedShareImage: null,
      lightboxOpen: false,
      lightboxStartIndex: 0
    };
  },

  methods: {
    isSelected(image) {
      return this.selectedImages.some(selected => selected.id === image.id);
    },

    toggleSelection(image) {
      if (this.isSelected(image)) {
        this.selectedImages = this.selectedImages.filter(selected => selected.id !== image.id);
      } else {
        this.selectedImages.push(image);
      }
    },

    handleExport() {
      if (this.selectedImages.length === 0) return;
      this.showExportDialog = true;
    },

    async exportImages(formatOptions) {
      if (this.selectedImages.length === 0) return;
      this.showExportDialog = false;
      this.isExporting = true;
      this.exportProgress = 0;

      try {
        const exportedImages = await ShootsApi.exportImages({
          imageIds: this.selectedImages.map(img => img.id),
          formatOptions
        });

        const JSZip = require('jszip');
        const zip = new JSZip();
        const totalImages = exportedImages.length;
        let completedImages = 0;

        await Promise.all(
          exportedImages.map(async image => {
            try {
              const response = await fetch(image.url);
              const blob = await response.blob();
              const filename = image.url.split('/').pop().split('?')[0];
              zip.file(filename, blob);
              completedImages++;
              this.exportProgress = Math.round((completedImages / totalImages) * 100);
            } catch (error) {
              console.error(`Error downloading image ${image.id}:`, error);
            }
          })
        );

        const content = await zip.generateAsync({ type: 'blob' });
        const url = window.URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = `formatted-images-${new Date().getTime()}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error in batch format and download:', error);
      } finally {
        this.isExporting = false;
        this.exportProgress = 0;
      }
    },

    getImageRatio(image) {
      return image.aspect_ratio || image.width / image.height;
    },

    getImageClass(image) {
      const aspectRatio = this.getImageRatio(image);
      if (aspectRatio > ASPECT_RATIO_THRESHOLD) {
        return 'grid-item--landscape';
      } else if (aspectRatio < TALL_RATIO_THRESHOLD) {
        return 'grid-item--portrait';
      }
      return '';
    },

    async handleDownload(image) {
      this.$set(this.downloadingStates, image.id, true);
      try {
        const a = document.createElement('a');
        a.href = image.url;
        a.target = '_blank'; // Open in new tab
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error downloading image:', error);
      } finally {
        this.$set(this.downloadingStates, image.id, false);
      }
    },

    async handleCopyLink(imageUrl) {
      try {
        await navigator.clipboard.writeText(imageUrl);
        this.$q.notify({
          type: 'primary',
          message: 'Link copied to clipboard'
        });
      } catch (error) {
        console.error('Error copying to clipboard:', error);
      }
    },

    shareToMeta(image) {
      this.selectedShareImage = image;
    },

    handleMetaShare() {
      this.$q.notify({
        type: 'positive',
        message: 'Image shared successfully!'
      });
    },
    toggleSelectAll() {
      if (this.selectedImages.length === this.images.length) {
        // Deselect all
        this.selectedImages = [];
      } else {
        // Select all
        this.selectedImages = [...this.images];
      }
    },
    openLightbox(index) {
      this.lightboxStartIndex = index;
      this.lightboxOpen = true;
    }
  }
};
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
}

.stats-bar {
  position: fixed;
  height: 60px;
  width: calc(100vw - 300px);
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding-right: 36px;
  padding-left: 6px;

  .stats-badge {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 4px;
  }
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-auto-rows: 275px;
  grid-auto-flow: dense;
  gap: 12px;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

.grid-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;

  &.selected {
    transform: scale(0.98);

    .grid-image {
      filter: brightness(0.7);
    }
  }

  &:hover .image-overlay {
    opacity: 1;
    pointer-events: auto;
  }

  &--landscape {
    grid-column: span 2;
    grid-row: span 2;
    @media (max-width: 900px) {
      grid-column: span 1;
      grid-row: span 1;
    }
  }

  &--portrait {
    grid-row: span 2;
  }
}

.selection-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  pointer-events: none;
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  transition: filter 0.3s ease;
  content-visibility: auto;
  contain: content;
  contain-intrinsic-size: 275px;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.overlay-info {
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;
}

.overlay-chip {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 18px;
  font-size: 12px;
  padding: 12px;
  color: #151515 !important;
}

.action-buttons {
  display: flex;
  gap: 6px;
  margin-top: auto;
  padding: 8px;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
}

.action-btn {
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151515;

  &:hover {
    transform: scale(1.1);
    color: var(--main-btn-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

@media (max-width: 600px) {
  .images-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: 200px;
  }
  .grid-image {
    contain-intrinsic-size: 200px;
  }
}
</style>
