<template>
  <div class="instagram-preview">
    <div class="preview-card">
      <div class="post-header">
        <div class="header-left">
          <q-avatar size="32px">
            <div v-if="selectedPageAvatar" class="profile-avatar">
              <img :src="selectedPageAvatar" alt="Profile" />
            </div>
            <div v-else class="default-avatar">
              <q-icon name="person" size="20px" color="grey-6" />
            </div>
          </q-avatar>
          <div class="header-meta">
            <div class="username">{{ selectedPageName }}</div>
            <div class="location">Instagram account</div>
          </div>
        </div>
        <q-btn flat round dense icon="more_horiz" size="sm" color="dark" />
      </div>

      <div class="post-media">
        <q-img v-if="previewUrl" :src="previewUrl" :placeholder-src="placeholderUrl" class="instagram-media" :ratio="imageRatio" fit="cover" />
        <div v-else class="placeholder-media" :style="{ aspectRatio: imageRatio }">
          <q-icon name="image" size="48px" color="grey-5" />
        </div>
      </div>

      <div class="instagram-actions">
        <div class="left-actions">
          <q-btn flat round dense icon="favorite_border" size="md" color="dark" />
          <q-btn flat round dense icon="chat_bubble_outline" size="md" color="dark" />
          <q-btn flat round dense icon="send" size="md" color="dark" />
        </div>
        <q-btn flat round dense icon="bookmark_border" size="md" color="dark" />
      </div>

      <div class="post-engagement">
        <div class="likes">1,234 likes</div>
      </div>

      <div class="post-content">
        <span class="username">{{ selectedPageName }}</span>
        <span class="caption">{{ content }}</span>
        <div class="timestamp">1 HOUR AGO</div>
      </div>

      <div class="post-comments">
        <div class="add-comment">
          <q-icon name="sentiment_satisfied" size="24px" class="emoji-icon" />
          <input type="text" placeholder="Add a comment..." />
          <span class="post-button">Post</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstagramPreview',
  props: {
    content: {
      type: String,
      required: true
    },
    previewUrl: String,
    placeholderUrl: String,
    imageRatio: {
      type: Number,
      default: 1
    },
    selectedPageName: {
      type: String,
      required: true
    },
    selectedPageAvatar: String
  }
};
</script>

<style lang="scss" scoped>
.instagram-preview {
  .preview-card {
    background: white;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    max-width: 600px;
    margin: 0 auto;
  }

  .post-header {
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;

    .header-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .default-avatar {
      width: 100%;
      height: 100%;
      background: #fafafa;
      border: 1px solid #dbdbdb;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header-meta {
      display: flex;
      flex-direction: column;

      .username {
        font-weight: 600;
        font-size: 14px;
        color: #262626;
        line-height: 1.2;
      }

      .location {
        font-size: 12px;
        color: #262626;
        line-height: 1.2;
      }
    }
  }

  .post-media {
    width: 100%;
    background: #fafafa;

    .instagram-media {
      width: 100%;
      max-height: none;
    }

    .placeholder-media {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fafafa;
    }
  }

  .instagram-actions {
    padding: 6px 16px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-actions {
      display: flex;
      gap: 16px;
    }
  }

  .post-engagement {
    padding: 0 16px 8px;

    .likes {
      font-weight: 600;
      font-size: 14px;
      color: #262626;
    }
  }

  .post-content {
    padding: 0 16px 16px;
    font-size: 14px;
    line-height: 1.4;
    color: #262626;

    .username {
      font-weight: 600;
      margin-right: 6px;
    }

    .caption {
      white-space: pre-wrap;
    }

    .timestamp {
      margin-top: 8px;
      font-size: 10px;
      letter-spacing: 0.2px;
      color: #8e8e8e;
      text-transform: uppercase;
    }
  }

  .post-comments {
    border-top: 1px solid #efefef;

    .add-comment {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 12px;

      .emoji-icon {
        color: #262626;
      }

      input {
        flex: 1;
        border: none;
        outline: none;
        font-size: 14px;
        color: #262626;
        background: transparent;

        &::placeholder {
          color: #8e8e8e;
        }
      }

      .post-button {
        color: #0095f6;
        font-weight: 600;
        font-size: 14px;
        opacity: 0.3;
        cursor: pointer;
      }
    }
  }
}
</style>
