<template>
  <div class="preview-card">
    <div class="post-header">
      <q-avatar size="40px" class="custom-avatar">
        <div v-if="selectedPageAvatar" class="profile-avatar">
          <img :src="selectedPageAvatar" alt="Profile" />
        </div>
        <div v-else class="initials-avatar">FP</div>
      </q-avatar>
      <div class="post-meta">
        <div class="post-page-name">{{ selectedPageName }}</div>
        <div class="post-time">{{ formattedDateTime }} · <q-icon name="public" size="xs" /></div>
      </div>
      <div class="post-actions-right" style="margin-left: auto">
        <q-btn flat round dense icon="more_horiz" size="sm" />
        <q-btn flat round dense icon="close" size="sm" />
      </div>
    </div>

    <div class="post-content">{{ content }}</div>
    <div v-if="previewUrl" class="media-preview-container">
      <q-img :src="previewUrl" :placeholder-src="placeholderUrl" class="media-preview" :ratio="imageRatio" fit="cover" style="max-width: 100%" />
    </div>

    <div class="post-actions">
      <div class="action-item"><q-icon name="thumb_up_off_alt" /> Like</div>
      <div class="action-item"><q-icon name="chat_bubble_outline" /> Comment</div>
      <div class="action-item"><q-icon name="share" /> Share</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FacebookPreview',
  props: {
    content: {
      type: String,
      required: true
    },
    previewUrl: String,
    placeholderUrl: String,
    imageRatio: Number,
    formattedDateTime: {
      type: String,
      required: true
    },
    selectedPageName: {
      type: String,
      required: true
    },
    selectedPageAvatar: String
  }
};
</script>

<style lang="scss" scoped>
.preview-card {
  background: white;
  border-radius: 4px;
  border: 1px solid #c2c2c2;
}

.post-header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 0.5px solid var(--border-color);

  .post-meta {
    margin-left: 12px;

    .post-page-name {
      font-weight: 600;
      font-size: 14px;
    }

    .post-time {
      font-size: 12px;
      color: var(--secondary-text-color);
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.custom-avatar {
  .initials-avatar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color, #1976d2);
    color: white;
    font-weight: 600;
    font-size: 16px;
  }

  .profile-avatar {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.post-content {
  padding: 16px;
  font-size: 14px;
  white-space: pre-wrap;
}

.media-preview-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.post-actions {
  display: flex;
  padding: 4px 16px;
  border-top: 1px solid var(--border-color);

  .action-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    color: var(--secondary-text-color);
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--hover-bg-color);
    }
  }
}
</style>
