<template>
  <div class="selectBusinessAccount">
    <BaseSelectWithFilter
      v-model="selectedBusinessAccount"
      option-label="company_name"
      option-value="client_id"
      use-input
      :label="$t('COMPANY.NAME')"
      :options="clientsData"
      :loading="isLoadingClients"
    />
  </div>
</template>

<script>
import { useQuery } from '@tanstack/vue-query';
import { computed } from '@vue/composition-api'; // Import from composition-api
// Api
import { UserApi } from '@api/index';

// Base Components
import BaseSelectWithFilter from '@base/BaseSelectWithFilter.vue';

export default {
  name: 'SelectBusinessAccount',
  components: {
    BaseSelectWithFilter
  },
  setup(props, { root, emit }) {
    const store = root.$store;
    const { data, isLoading } = useQuery({
      queryKey: ['clients'],
      queryFn: async () => {
        const response = await UserApi.searchUsers({
          payload: {
            filters: { role: 'client', statuses: ['active'] },
            fetchAll: true
          }
        });
        return response.data.users || [];
      },
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 60 * 8 // Cache for 8 hours
    });

    const clientsData = computed(() => data.value);
    const isLoadingClients = computed(() => isLoading.value);

    const shoot = computed(() => store.getters['bookShoot/getShoot']);
    const selectedBusinessAccount = computed({
      get() {
        return clientsData.value?.find(user => shoot.value.client_id === user.client_id)?.company_name || null;
      },
      set(value) {
        emit('client:selected', value);
      }
    });

    return {
      clientsData,
      isLoadingClients,
      selectedBusinessAccount
    };
  }
};
</script>

<style lang="scss" scoped>
.selectBusinessAccount {
  width: 300px;

  > p {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(14px);
    margin: 0;
    margin-bottom: 20px;
    text-align: left;
  }
}
</style>
