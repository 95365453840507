<template>
  <q-dialog v-model="show" persistent>
    <q-card class="format-dialog">
      <q-card-section class="header-section" style="display: flex; justify-content: space-between">
        <div class="text-h6">Images export settings</div>
        <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
      </q-card-section>

      <q-card-section class="dialog-content">
        <q-expansion-item icon="tune" default-opened label="Resolution presets" caption="Choose output quality and file size">
          <q-list padding>
            <q-item
              v-for="preset in formatPresets"
              :key="preset.name"
              clickable
              @click="handlePresetSelect(preset)"
              :active="isCurrentPreset(preset)"
              v-ripple
              class="preset-item"
              active-class="bg-grey-4 text-black"
            >
              <q-item-section>
                <q-item-label>{{ preset.name }}</q-item-label>
                <q-item-label caption>{{ preset.description }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-item-label caption>{{ preset.letters }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-expansion-item>

        <!-- Watermark Section -->
        <q-expansion-item v-model="watermarkEnabled" icon="branding_watermark" label="Watermark options" caption="Add and customize images watermark">
          <!-- Watermark Image Upload -->
          <div class="uploader-container">
            <q-uploader
              label="Upload watermark image"
              hide-upload-btn
              accept=".jpg,.jpeg,.png"
              :max-files="1"
              @added="handleWatermarkUpload"
              no-thumbnails
              flat
              bordered
              color="grey-4"
              text-color="grey-8"
            />
            <div class="watermark-preview">
              <img v-if="watermarkImage" :src="watermarkImage" alt="Watermark Preview" style="width: 100%; height: 100%; object-fit: contain" />
              <q-icon v-else name="o_image" size="100px" color="grey-2" />
            </div>
          </div>

          <!-- Opacity Slider -->
          <div class="text-caption" style="margin-bottom: 6px">Opacity</div>
          <q-slider v-model="watermarkOpacity" :min="0" :max="100" label label-always label-text-color="grey-8" color="grey-4" text-color="grey-8" style="margin-bottom: 12px" />

          <!-- Size Ratio -->
          <div class="text-caption" style="margin-bottom: 6px">Size (% of image width)</div>
          <q-slider v-model="watermarkSize" :min="5" :max="50" label label-always label-text-color="grey-8" color="grey-4" />

          <!-- Position Select -->
          <div class="text-caption" style="margin-bottom: 12px">Watermark Position</div>
          <q-select v-model="watermarkPosition" :options="watermarkPositions" outlined dense emit-value map-options class="q-mb-md" />
        </q-expansion-item>

        <!-- Watermark checkbox -->
        <q-checkbox v-model="watermarkEnabled" label="Apply watermark to images" style="margin-bottom: 12px; margin-top: 12px" color="grey-8" />
      </q-card-section>

      <q-card-actions align="right" class="action-section q-pa-md">
        <q-btn flat label="Cancel" no-caps color="grey-7" v-close-popup class="q-px-md" @click="handleCancel" />
        <q-btn unelevated no-caps label="Download Images" color="primary" textColor="#757575" @click="handleFormat" class="q-px-md" :disabled="!hasSelectedPreset" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ImageFormatDialog',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      selectedPreset: null,
      watermarkEnabled: false,
      watermarkImage: null,
      watermarkOpacity: 60,
      watermarkSize: 20,
      watermarkPosition: 'bottom-right',
      watermarkPositions: [
        { value: 'top-left', label: 'Top Left' },
        { value: 'top-right', label: 'Top Right' },
        { value: 'bottom-left', label: 'Bottom Left' },
        { value: 'bottom-right', label: 'Bottom Right' },
        { value: 'center', label: 'Center' }
      ],
      formatPresets: [
        {
          name: 'HD (1280×720)',
          description: 'Perfect for social posts',
          letters: '~2MB',
          settings: {
            width: 1280,
            height: 720,
            fit: 'fit-in',
            format: 'jpeg',
            quality: 85,
            preserveAspectRatio: true
          }
        },
        {
          name: 'Full HD (1920×1080)',
          description: 'Standard quality for websites and online use',
          letters: '~5MB',
          settings: {
            width: 1920,
            height: 1080,
            fit: 'fit-in',
            format: 'jpeg',
            quality: 85,
            preserveAspectRatio: true
          }
        },
        {
          name: 'QHD (2560×1440)',
          description: 'High quality for professional printing',
          letters: '~8MB',
          settings: {
            width: 2560,
            height: 1440,
            fit: 'fit-in',
            format: 'jpeg',
            quality: 85,
            preserveAspectRatio: true
          }
        },
        {
          name: 'Original Size',
          description: 'Maximum quality for long-term storage',
          letters: '~15MB',
          settings: {
            fit: 'fit-in',
            format: 'jpeg',
            quality: 100,
            preserveAspectRatio: true
          }
        }
      ]
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    hasSelectedPreset() {
      return this.formatPresets.some(preset => this.isCurrentPreset(preset));
    }
  },

  methods: {
    handlePresetSelect(preset) {
      this.selectedPreset = preset;
    },

    isCurrentPreset(preset) {
      return this.selectedPreset === preset;
    },

    handleWatermarkUpload(files) {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.watermarkImage = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    handleFormat() {
      if (this.selectedPreset) {
        const settings = {
          ...this.selectedPreset.settings
        };

        // Add watermark settings if enabled
        if (this.watermarkEnabled && this.watermarkImage) {
          // Map position to Thumbor coordinates
          let x = '0',
            y = '0';
          switch (this.watermarkPosition) {
            case 'top-left':
              x = '10p';
              y = '10p';
              break;
            case 'top-right':
              x = '-10p';
              y = '10p';
              break;
            case 'bottom-left':
              x = '10p';
              y = '-10p';
              break;
            case 'bottom-right':
              x = '-10p';
              y = '-10p';
              break;
            case 'center':
              x = 'center';
              y = 'center';
              break;
          }

          // Construct watermark settings for Thumbor
          settings.watermark = {
            image: this.watermarkImage, // Send the full base64 string
            x: x,
            y: y,
            alpha: 100 - this.watermarkOpacity, // Convert opacity to alpha (inverse)
            w_ratio: this.watermarkSize.toString(), // Width as percentage
            h_ratio: 'none' // Keep aspect ratio
          };
        }
        this.$emit('format', settings);
        this.show = false;
        this.selectedPreset = null;
      }
    },

    handleCancel() {
      this.$emit('cancel');
      this.show = false;
      this.selectedPreset = null;
      this.watermarkEnabled = false;
      this.watermarkImage = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.format-dialog {
  @include inter-font($size: 14px);
  border-radius: 12px;
  min-width: 550px !important;
  max-height: 580px !important;
  overflow-y: scroll;

  .header-section {
    padding: 20px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  .dialog-content {
    padding: 12px;
  }

  .preset-item {
    margin-bottom: 6px;
    border-radius: 6px;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
  }

  .watermark-section {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    padding-left: 24px;
  }

  .close-btn {
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  .action-section {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    @include inter-font($size: 14px);
  }

  .uploader-container {
    display: grid;
    grid-template-columns: 2fr 1.15fr;
    min-height: 115px;
    margin-top: 12px;
    margin-bottom: 12px;
    height: auto;
  }

  .watermark-preview {
    /* border: 0.5px solid rgba(0, 0, 0, 0.25); */
    border-radius: 6px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
