<template>
  <div class="search-container">
    <div class="search-wrapper">
      <div class="search-header">
        <p class="search-title">
          Use natural language to find content
          <span class="ai-badge">AI POWERED</span>
        </p>
        <button class="show-ideas-button" @click="toggleIdeas">
          {{ showIdeas ? 'Hide prompt ideas' : 'Show prompt ideas' }}
          <q-icon :name="showIdeas ? 'lightbulb' : 'o_lightbulb'" :color="!showIdeas ? 'grey-6' : 'primary'" size="20px" style="margin-left: 6px" />
        </button>
      </div>

      <div class="search-box-container">
        <div class="search-box-wrapper">
          <div class="search-input-wrapper">
            <i class="sparkles-icon"></i>
            <input v-model="searchQuery" class="search-input" type="text" placeholder="Search for 'a wedding with blue tones' or 'a cozy living room at sunset'" @keyup.enter="submitSearch" />
            <button class="send-icon-button" @click="submitSearch" type="button">
              <q-icon name="send" color="grey-6" size="20px" />
            </button>
          </div>
        </div>
      </div>

      <transition name="expand">
        <div v-if="showIdeas" class="prompt-ideas">
          <div class="category-grid">
            <button v-for="category in categories" :key="category.title" class="category-button" :class="{ 'selected-prompt': selectedPrompt === category.prompt }" @click="selectCategory(category)">
              <span class="category-title">{{ category.title }}</span>
              <span class="category-description">{{ category.description }}</span>
              <span class="category-prompt">{{ category.prompt }}</span>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GalleryTopBar',

  data() {
    return {
      searchQuery: '',
      showIdeas: false,
      selectedPrompt: null,
      categories: [
        {
          title: 'Real Estate',
          description: 'Interior & exterior property shots',
          prompt: 'A sun-drenched living room with a city view at night'
        },
        {
          title: 'Product',
          description: 'Studio & lifestyle photography',
          prompt: 'A close-up of a watch on a textured gray surface'
        },
        {
          title: 'Events',
          description: 'Corporate & social gatherings',
          prompt: 'A red carpet premiere event with people dressed formally'
        },
        {
          title: 'Food & Beverage',
          description: 'Culinary and drink photography',
          prompt: 'A close-up of a dish with a dip and vibrant colors'
        },
        {
          title: 'Travel',
          description: 'Landscapes & cultural experiences',
          prompt: 'An aerial view of a city with an urban skyline and blue hues'
        },
        {
          title: 'Fashion',
          description: 'Editorial & street style',
          prompt: 'A fashion shoot with an adult woman wearing a grey gown'
        }
      ]
    };
  },
  watch: {
    searchQuery(newVal) {
      this.$emit('search', newVal);
    }
  },
  methods: {
    toggleIdeas() {
      this.showIdeas = !this.showIdeas;
      this.selectedPrompt = null;
    },
    selectCategory(category) {
      this.searchQuery = category.prompt;
      this.selectedPrompt = category.prompt;
    },
    submitSearch() {
      this.$emit('search', this.searchQuery);
    }
  }
};
</script>

<style scoped>
.search-container {
  position: sticky;
  top: 0;
  z-index: 50;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.search-wrapper {
  padding: 18px;
  padding-top: 6px;
}

.search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.search-title {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -0.025em;
  color: #151515;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 12px;
}

.ai-badge {
  font-size: 9px;
  padding: 3px;
  color: #7c3aed;
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-family: monospace;
  letter-spacing: 1.5px;
  position: relative; /* Required for absolute positioning of the pseudo-element */
  border: 0.5px solid #7c3aed;
}

.show-ideas-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background: white;
  font-size: 0.75rem;
  color: #374151;
  cursor: pointer;
  transition: all 0.2s;
}

.show-ideas-button:hover {
  background: #f9fafb;
}

.search-box-wrapper {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background: linear-gradient(to right, rgba(124, 58, 237, 0.15), /* Light purple */ rgba(59, 130, 246, 0.15) /* Light blue */);
  transition: all 0.2s;
}

.search-box-wrapper:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.search-input-wrapper {
  position: relative;
}

.sparkles-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  background: #7c3aed;
  mask: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"/><path d="M5 3v4"/><path d="M19 17v4"/><path d="M3 5h4"/><path d="M17 19h4"/></svg>');
  mask-size: contain;
  mask-repeat: no-repeat;
  animation: pulse 2s infinite;
}

.search-input {
  width: 100%;
  height: 56px;
  padding: 0 3rem 0 3rem; /* Adjusted padding to accommodate the send icon */
  border: none;
  background: transparent;
  font-size: 16px;
  color: #1a1a1a;
}

.search-input:focus {
  outline: none;
}

.search-input::placeholder {
  color: #6b7280;
}

.search-suggestions {
  text-align: center;
  font-size: 0.875rem;
  color: #6b7280;
}

.prompt-ideas {
  overflow: hidden;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 18px;
}

.category-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background: transparent;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
  text-align: left;
}

.category-button:hover {
  background: rgba(124, 58, 237, 0.05);
}
.category-button.selected-prompt {
  background: linear-gradient(to right, rgba(124, 58, 237, 0.15), /* Light purple */ rgba(59, 130, 246, 0.15) /* Light blue */);
  border: 1px solid rgba(124, 58, 237, 0.5);
}

.category-title {
  color: #7c3aed;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.category-description {
  font-size: 0.75rem;
  color: #6b7280;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  max-height: 1000px;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

@media (max-width: 640px) {
  .category-grid {
    grid-template-columns: 1fr;
  }

  .search-title {
    font-size: 1.5rem;
  }
}

.send-icon-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
}
</style>
