import jwt_decode from 'jwt-decode';
import store from '../store/index';
import Vue from 'vue';
import Router from 'vue-router';

// Routes
import accounts from './modules/accounts';
import quotes from './modules/quotes';
import home from './modules/home';
import packages from './modules/packages';
import registration from './modules/registration';
import report from './modules/report';
import settings from './modules/settings';
import shoot from './modules/shoot';
import stripe from './modules/stripe';
import gallery from './modules/gallery';
import integrations from './modules/integrations';

import { getCookie, setCookie } from '@utils/cookies';

Vue.use(Router);

const routes = [...accounts, ...home, ...packages, ...registration, ...report, ...settings, ...shoot, ...stripe, ...quotes, ...gallery, ...integrations];

const router = new Router({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const jwt = getCookie('jwt');
  const isAdmin = store.getters['user/isAdmin'];
  const user = store.getters['user/getUser'];

  if (jwt) {
    decoded_token = jwt_decode(jwt);
    // Setting user on store
    store.dispatch('user/saveUser', { ...decoded_token });
    // Set user properties for Google Analytics tracking
    Vue.prototype.$gtag.set({
      user_properties: {
        name: decoded_token.name,
        role: decoded_token.role,
        company_name: decoded_token.company_name
      },
      user_id: decoded_token.sub // Using subject claim as unique user ID
    });
  }

  if (to.name === 'phRegistration' || to.name === 'businessRegistration' || to.name === 'stripeSuccess' || to.name === 'stripeError') {
    next();
    return;
  }
  if (to.name === 'flashyShoots') {
    if (!jwt) {
      next();
      return;
    } else {
      // Check if the user is not an admin and is a B2C client
      if (!isAdmin && user.company_name && user.company_name.endsWith('B2C')) {
        next(); // Allow access for B2C clients and guests
        return;
      } else {
        // Redirect to the home page for admins and Bespoke clients
        window.location.replace(`${process.env.VUE_APP_BASE_DOMAIN}/login?__redirect_uri=${process.env.VUE_APP_FE_DOMAIN}/`);
        return;
      }
    }
  }

  /**
   * If the applicaton is running in FE dev env, skip token validation
   */
  if (process.env.VUE_APP_MOCK === 'true') {
    next();
    return;
  }
  // Retrieving the bearer token from the url
  const bearer_token = to.query.__bearer_access_token;
  let decoded_token;
  /**
   * If the bearer token exists, it means we are redirecting from the login page so we need to:
   * 1. set the cookie
   * 2. set the logged user
   * 3. redirect to home page
   */
  if (bearer_token) {
    decoded_token = jwt_decode(bearer_token);
    // 1.
    const expiration = decoded_token.exp;
    setCookie({ cookieName: 'jwt', cookieValue: bearer_token, expiration });
    // 2.
    store.dispatch('user/saveUser', { ...decoded_token });
    // Set user properties for Google Analytics tracking
    Vue.prototype.$gtag.set({
      user_properties: {
        name: decoded_token.name,
        role: decoded_token.role,
        company_name: decoded_token.company_name
      },
      user_id: decoded_token.sub // Using subject claim as unique user ID
    });
    // 3.

    next({
      name: 'home'
    });
    return;
  }

  /**
   * If the cookies is not set, and we are not navigating to the login page, we need to redirect the user
   * to the login page
   */
  if (!jwt) {
    window.location.replace(`${process.env.VUE_APP_BASE_DOMAIN}/login?__redirect_uri=${process.env.VUE_APP_FE_DOMAIN}/`);
    return;
  }

  next();
});

// Optional: Add route tracking middleware
router.afterEach(to => {
  // Track pageview with enhanced data
  Vue.prototype.$gtag.pageview({
    page_path: to.path,
    page_title: to.name,
    page_location: window.location.href
  });
});

export default router;
