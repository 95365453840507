import instance from '../index';

export const MetaApi = {
  /**
   * Post an image to a Facebook page
   * @param {string} pageId - Facebook Page ID
   * @param {Object} payload - Post data
   * @param {string} payload.imageUrl - URL of the image to post
   * @param {string} payload.message - Text message for the post
   * @returns {Promise<Object>} Post creation result
   * @property {boolean} success - Operation success status
   * @property {string} postId - Created Facebook post ID
   * @property {string} photoId - Uploaded photo ID
   * @property {string} pageId - Facebook page ID
   * @property {string} pageName - Facebook page name
   */
  createFacebookPost(pageId, payload) {
    return instance.post(`/facebook/pages/${pageId}/post`, payload);
  },
  /**
   * Post an image to an Instagram business account
   * @param {string} businessId - Instagram Business ID
   * @param {Object} payload - Post data
   * @param {string} payload.imageUrl - URL of the image to post
   * @param {string} payload.message - Text message for the post
   * @returns {Promise<Object>} Post creation result
   * @property {boolean} success - Operation success status
   * @property {string} postId - Created Instagram post ID
   * @property {string} photoId - Uploaded photo ID
   * @property {string} businessId - Instagram business ID
   */
  createInstagramPost(businessId, payload) {
    return instance.post(`/instagram/business/${businessId}/post`, payload);
  }
};

export default MetaApi;
