import store from '@/store/index';

export default [
  {
    path: '/integrations',
    name: 'integrations',
    beforeEnter: (to, from, next) => {
      // Only the admin can access the quotes page
      const isAdmin = store.getters['user/isAdmin'];
      if (isAdmin) {
        next();
        return;
      }
      next({
        name: 'home'
      });
    },
    component: () => import(/* webpackChunkName: "quotes" */ '@/views/client/Integrations.vue')
  }
];
