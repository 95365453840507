<template>
  <div class="post-content-container q-mt-md">
    <div class="section-header">
      <p class="section-label">Post content</p>
      <q-btn flat round dense color="primary" class="ai-button">
        <i class="wand-icon"></i>
        <q-tooltip anchor="center right" self="center left" :offset="[10, 0]"> Generate post content with AI </q-tooltip>
      </q-btn>
    </div>
    <q-input
      :value="content"
      @input="handleContentInput"
      type="textarea"
      outlined
      class="post-textarea"
      placeholder="Write your post content here..."
      :error="hasError"
      :error-message="errorMessage"
    />

    <div class="tags-section">
      <div class="section-header">
        <p class="section-label">Hashtags</p>
        <q-btn flat round dense color="primary" class="ai-button">
          <i class="sparkles-icon"></i>
          <q-tooltip anchor="center right" self="center left" :offset="[10, 0]"> Generate relevant hashtags with AI </q-tooltip>
        </q-btn>
      </div>
      <div class="row items-center">
        <q-input :value="newHashtag" @input="$emit('update:new-hashtag', $event)" @keyup.enter="$emit('add-hashtag')" outlined class="tag-input" placeholder="Add hashtag...">
          <template v-slot:prepend>
            <q-icon name="tag" size="sm" />
          </template>
        </q-input>
      </div>
      <div v-if="hashtags.length > 0">
        <q-chip v-for="tag in hashtags" :key="tag" removable @remove="$emit('remove-hashtag', tag)" color="grey-4" text-color="grey-10" class="tag-chip" size="12px">
          #{{ tag.replace(/^#/, '') }}
        </q-chip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostContent',
  props: {
    content: {
      type: String,
      required: true
    },
    hashtags: {
      type: Array,
      required: true
    },
    newHashtag: {
      type: String,
      required: true
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      required: true
    }
  },
  emits: ['update:content', 'update:hashtags', 'update:new-hashtag', 'validate', 'add-hashtag', 'remove-hashtag'],
  setup(props, { emit }) {
    const handleContentInput = value => {
      emit('update:content', value);
      emit('validate');
    };

    return {
      handleContentInput
    };
  }
};
</script>

<style lang="scss" scoped>
.post-content-container {
  margin-top: 24px;

  .content-header {
    @include inter-font($size: 16px, $bolded: true, $color: var(--main-text-color));
    margin-bottom: 6px;
  }

  .post-textarea {
    width: 100%;
    margin: 0 !important;
  }
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  .section-label {
    @include inter-font($size: 16px, $color: var(--main-text-color));
    margin: 0;
  }

  .ai-button {
    position: relative;
    transition: all 0.2s ease;
    margin-bottom: 3px;

    &:hover {
      background: linear-gradient(135deg, rgba(99, 102, 241, 0.2) 0%, rgba(59, 130, 246, 0.2) 100%);
      transform: scale(1.05);
    }

    .sparkles-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.25rem;
      height: 1.25rem;
      background: #7c3aed;
      mask: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M15 4V2"/><path d="M15 16v-2"/><path d="M8 9h2"/><path d="M20 9h2"/><path d="M17.8 11.8 19 13"/><path d="M15 9h0"/><path d="M17.8 6.2 19 5"/><path d="m3 21 9-9"/><path d="M12.2 6.2 11 5"/></svg>');
      mask-size: contain;
      mask-repeat: no-repeat;
      animation: pulse 2s infinite;
    }

    .wand-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.25rem;
      height: 1.25rem;
      background: #7c3aed;
      mask: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"/><path d="M5 3v4"/><path d="M19 17v4"/><path d="M3 5h4"/><path d="M17 19h4"/></svg>');
      mask-size: contain;
      mask-repeat: no-repeat;
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.tags-section {
  .tag-input {
    width: 100%;
  }

  .tag-chip {
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
