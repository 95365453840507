<template>
  <div>
    <p class="section-label">Connected business assets</p>
    <div class="cards-grid">
      <!-- Facebook Pages Card -->
      <q-card v-for="page in metaIntegration.pages" :key="page.id" :class="{ selected: isAccountSelected(page.id), unpublished: !page.isPublished }" @click="toggleAccount(page)" flat bordered>
        <q-item>
          <q-item-section avatar>
            <q-icon name="fab fa-facebook" class="facebook-icon" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label class="row items-center">
              {{ page.name }}
              <q-icon v-if="page.verificationStatus === 'verified'" name="check_circle" color="primary" size="sm" class="q-ml-sm" />
            </q-item-label>
            <q-item-label caption>{{ page.category }}</q-item-label>
            <div class="row items-center q-gutter-x-sm q-mt-xs">
              <q-chip size="sm">{{ formatNumber(page.followersCount) }} followers</q-chip>
              <q-chip v-if="!page.isPublished" size="sm">Unpublished</q-chip>
            </div>
          </q-item-section>
          <q-item-section side>
            <q-checkbox v-model="localSelectedAccounts" :val="page" color="primary" />
          </q-item-section>
        </q-item>
      </q-card>

      <!-- Instagram Accounts Card -->
      <q-card
        v-for="account in metaIntegration.instagramAccounts"
        :key="account.id"
        :class="{ selected: isAccountSelected(account.id), unpublished: !account.isPublished }"
        @click="toggleAccount(account)"
        flat
        bordered
      >
        <q-item>
          <q-item-section avatar>
            <q-avatar>
              <q-icon name="fab fa-instagram" size="lg" class="instagram-icon" />
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ account.username }}</q-item-label>
            <q-item-label caption>{{ account.connectedPageName }}</q-item-label>
            <div class="row items-center q-gutter-x-sm q-mt-xs">
              <q-chip size="sm">{{ formatNumber(account.followedByCount) }} followers</q-chip>
              <q-chip v-if="account.isPrivate" size="sm" color="warning">Private</q-chip>
              <q-chip v-if="!account.isPublished" size="sm">Unpublished</q-chip>
            </div>
          </q-item-section>
          <q-item-section side>
            <q-checkbox v-model="localSelectedAccounts" :val="account" color="primary" />
          </q-item-section>
        </q-item>
      </q-card>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  name: 'BusinessAssets',
  props: {
    metaIntegration: {
      type: Object,
      required: true
    },
    selectedAccounts: {
      type: Array,
      required: true
    },
    formatNumber: {
      type: Function,
      required: true
    },
    isAccountSelected: {
      type: Function,
      required: true
    },
    toggleAccount: {
      type: Function,
      required: true
    }
  },
  emits: ['update:selected-accounts'],
  setup(props, { emit }) {
    const localSelectedAccounts = computed({
      get: () => props.selectedAccounts,
      set: value => emit('update:selected-accounts', value)
    });

    return {
      localSelectedAccounts
    };
  }
};
</script>

<style lang="scss" scoped>
.cards-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.q-card {
  cursor: pointer;
  transition: all 0.2s ease;
  border: 0;
  border-radius: 4px !important;
  border: 1px solid #c2c2c2;

  &:hover {
    background: linear-gradient(135deg, rgba(99, 102, 241, 0.1) 0%, rgba(59, 130, 246, 0.1) 100%);
  }

  &.selected {
    background: linear-gradient(135deg, rgba(99, 102, 241, 0.1) 0%, rgba(59, 130, 246, 0.1) 100%);
  }
}

.section-label {
  @include inter-font($size: 16px, $color: var(--main-text-color));
  margin-bottom: 6px;
}

.instagram-icon {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.facebook-icon {
  background: linear-gradient(45deg, #0062e0 0%, #19afff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
