<template>
  <div class="preview-container">
    <div class="preview-header">
      <p class="section-label">Preview post</p>
      <q-btn-toggle
        :value="previewType"
        @input="$emit('update:preview-type', $event)"
        flat
        :options="[
          { label: 'Facebook', value: 'facebook', icon: 'fab fa-facebook' },
          { label: 'Instagram', value: 'instagram', icon: 'fab fa-instagram' }
        ]"
        class="preview-toggle rounded-borders"
        no-caps
        :toggle-color="previewType === 'facebook' ? 'blue' : 'pink-6'"
        rounded
      />
    </div>

    <FacebookPreview
      v-if="previewType === 'facebook'"
      :content="content"
      :preview-url="previewUrl"
      :placeholder-url="placeholderUrl"
      :image-ratio="imageRatio"
      :formatted-date-time="formattedDateTime"
      :selected-page-name="selectedPageName"
      :selected-page-avatar="selectedPageAvatar"
    />

    <InstagramPreview v-else :content="content" :preview-url="previewUrl" :placeholder-url="placeholderUrl" :image-ratio="imageRatio" />
  </div>
</template>

<script>
import FacebookPreview from './FacebookPreview.vue';
import InstagramPreview from './InstagramPreview.vue';

export default {
  name: 'PreviewSection',
  components: {
    FacebookPreview,
    InstagramPreview
  },
  props: {
    previewType: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    previewUrl: String,
    placeholderUrl: String,
    imageRatio: {
      type: Number,
      default: 1
    },
    formattedDateTime: {
      type: String,
      required: true
    },
    selectedPageName: {
      type: String,
      required: true
    },
    selectedPageAvatar: String
  },
  emits: ['update:preview-type']
};
</script>

<style lang="scss" scoped>
.preview-container {
  padding-left: 24px;
  height: 100%;
  overflow-y: auto;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .preview-toggle {
    background-color: white;

    .q-btn {
      padding: 8px 16px;
    }
  }
}

.section-label {
  @include inter-font($size: 16px, $bolded: false, $color: var(--main-text-color));
  margin: 0;
  padding: 0;
}
</style>
