import store from '../../store/index';
import instance from '../index';

// Utils
import { CANCELED } from '@utils/shootsHelpers';

const RESOURCE_ACTION = 'action';
const RESOURCE_SHOOT = 'shoot';
const RESOURCE_SHOOTS = 'shoots';
const RESOURCE_SEARCH = 'search';

export const ShootsApi = {
  /**
   * Assign photographer
   */
  assignPhotographer({ shoot_id, photographer_id }) {
    return instance.post(`${RESOURCE_SHOOT}/${shoot_id}/${RESOURCE_ACTION}/assign-photographer`, {
      photographer_id
    });
  },
  /**
   * Method used to cancel a shoot. It will call the updateStatusApi with 'CANCELED' as status
   */
  cancel(id) {
    return this.updateStatus({
      id,
      payload: {
        targetStatus: CANCELED
      }
    });
  },
  /**
   * Api call to create a new shoot
   */
  createShoot({ payload }) {
    const payloadClone = JSON.parse(JSON.stringify(payload));
    const type = payloadClone.type;

    if (type === 'custom') {
      payloadClone.is_payed = true;
    } else if (type === 'express') {
      let price = 0;
      let revenue = 0;

      payloadClone.packages.forEach(element => {
        price += Number(element.price);
        revenue += Number(element.photographer_revenue);

        delete element.duration;
        delete element.price;
        delete element.photographer_revenue;
        delete element.picture_number;
        delete element.name;
        delete element.service_id;
      });

      delete payloadClone.total_price;
      delete payloadClone.total_revenue;

      if (store.getters['user/isAdmin']) {
        payloadClone.total_price = price;
        payloadClone.total_revenue = revenue;

        payloadClone.photographer_id = null;
      }
    }

    payloadClone.type = type;

    return instance.post(`/${RESOURCE_SHOOT}`, payloadClone);
  },
  /**
   * Api call to edit shoot
   */
  editShoot({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/edit/${id}`, payload);
  },
  /**
   * Get shoots search filters
   */
  getFilters() {
    return instance.get(`/${RESOURCE_SHOOT}/${RESOURCE_ACTION}/get-shoots-filters`);
  },
  /**
   * Api call to schedule shoot
   */
  scheduleShoot({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/schedule/${id}`, payload);
  },
  searchShoots(payload) {
    return instance.post(`/${RESOURCE_SHOOTS}/${RESOURCE_SEARCH}`, payload);
  },
  /**
   * Api call to remove a photographer from a given shoot
   * @param {Number} id shoot id
   * @returns Api call response
   */
  unassignPhotographer(id) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/unassign-photographer`);
  },
  updateStatus({ id, payload }) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/update-status`, payload);
  },
  getShootById({ shoot_id }) {
    return instance.get(`/${RESOURCE_SHOOT}/${shoot_id}`);
  }
};
