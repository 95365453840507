<template>
  <div class="gallery-container">
    <div class="sidebar q-pa-md">
      <template v-if="user.role === 'admin'">
        <!-- Client Filter -->
        <div class="input-caption">Filter content by client</div>
        <q-select
          v-model="selectedClient"
          outlined
          :options="filteredClientOptions"
          use-input
          input-debounce="0"
          @filter="filterFn"
          :loading="isLoadingClients"
          transition-show
          behavior="menu"
          style="margin-bottom: 18px; font-size: 14px !important"
          :placeholder="isLoadingClients ? 'Loading clients...' : selectedClient ? '' : 'Select client'"
          clearable
          @update:model-value="handleClientSelect"
        >
          <template v-slot:prepend>
            <q-icon name="o_person" size="20px" />
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey"> No results </q-item-section>
            </q-item>
          </template>
        </q-select>
      </template>

      <!-- Business Context Search -->
      <div class="input-caption">
        Search content by shoot metadata
        <q-icon name="info" size="18px" class="info-icon">
          <q-tooltip max-width="150px"> Search by shoot ID, shoot name, notes, outlet details, or filename </q-tooltip>
        </q-icon>
      </div>
      <q-input outlined v-model="metadataSearchQuery" placeholder="Enter search term..." style="margin-bottom: 18px" clearable @update:model-value="debouncedHandleMetadataSearch">
        <template v-slot:prepend>
          <q-icon name="search" size="20px" />
        </template>
      </q-input>

      <!-- Date Range Filter -->
      <div class="input-caption">Filter content by shoot date</div>
      <q-date v-model="dateRange" range today-btn minimal style="max-width: 300px" flat bordered @update:model-value="handleDateRangeChange" />

      <div class="input-caption" style="margin-top: 6px">Additional filters</div>
      <q-list bordered class="rounded-borders" style="margin-top: 6px">
        <!-- Aspect Ratio Filter -->
        <q-expansion-item expand-separator style="text-align: left" group="filters">
          <template v-slot:header>
            <q-icon size="20px" name="o_aspect_ratio" color="grey-6" style="align-self: center; margin-right: 12px" />
            <q-item-section class="expansion-item-text"> Aspect ratio </q-item-section>
          </template>

          <q-option-group v-model="selectedAspectRatios" :options="aspectRatioOptions" type="checkbox" @input="handleAspectRatioChange" class="checkbox" />
        </q-expansion-item>

        <!-- Services Filter -->
        <q-expansion-item expand-separator style="text-align: left" group="filters">
          <template v-slot:header>
            <q-icon size="20px" name="o_category" color="grey-6" style="align-self: center; margin-right: 12px" />
            <q-item-section class="expansion-item-text">Category </q-item-section>
          </template>
          <q-option-group v-model="selectedServices" :options="serviceOptions" type="checkbox" @input="handleServicesChange" class="checkbox" style="text-align: left" />
        </q-expansion-item>
      </q-list>

      <!-- Clear Filters -->
      <q-btn label="Clear filters" no-caps class="full-width q-mt-lg bg-grey-2" flat @click="clearFilters" :disable="!hasActiveFilters" />
    </div>

    <div class="gallery-content" @scroll="handleScroll" ref="scrollContainer">
      <GalleryTopBar @search="handleVisualSearch" />
      <!-- Initial Loading State -->
      <div v-if="imagesQuery.isLoading.value" class="row justify-center items-center full-height">
        <q-spinner-dots color="#151515" size="80px" />
      </div>

      <!-- Content -->
      <template v-else>
        <ImagesGrid
          v-if="allImages.length && currentPagination.length !== 0"
          :images="allImages"
          :pagination="currentPagination"
          :isMetaConnected="isMetaConnected"
          :metaIntegration="metaIntegration"
        />

        <!-- Empty State -->
        <div v-else-if="!imagesQuery.isFetching.value" class="row justify-center items-center full-height">
          <div class="text-subtitle1 text-grey">No images found</div>
        </div>

        <!-- Bottom Loading Spinner -->
        <div v-if="shouldShowLoadingSpinner" class="row justify-center q-my-md">
          <q-spinner-dots color="#151515" size="60px" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import { useInfiniteQuery, useQuery } from '@tanstack/vue-query';
import debounce from 'lodash/debounce';
import { ShootsApi, IntegrationsApi, UserApi } from '@api/index';
import ImagesGrid from '@components/ImagesGrid.vue';
import GalleryTopBar from '../components/GalleryTopBar.vue';

export default {
  name: 'Gallery',
  components: { ImagesGrid, GalleryTopBar },

  setup(props, { root }) {
    const store = root.$store;
    const scrollContainer = ref(null);
    const dateRange = ref({ from: null, to: null });
    const selectedServices = ref([]);
    const selectedAspectRatios = ref([]);
    const metadataSearchQuery = ref('');
    const visualSearchQuery = ref('');
    const debouncedVisualQuery = ref('');
    const selectedClient = ref(null);
    const allClients = ref([]);
    const clientOptions = ref([]);

    const user = computed(() => root.$store.getters['user/getUser']);

    const services = computed(() => store.getters['services/getServices']);
    const serviceOptions = computed(() =>
      services.value.map(service => ({
        label: service.name,
        value: service.id
      }))
    );
    const aspectRatioOptions = [
      { label: 'Square', value: 'square' },
      { label: 'Landscape', value: 'landscape' },
      { label: 'Portrait', value: 'portrait' }
    ];

    const { data: clientsData, isLoading: isLoadingClients } = useQuery({
      queryKey: ['clients'],
      queryFn: async () => {
        const response = await UserApi.searchUsers({
          payload: {
            filters: { role: 'client', statuses: ['active'] },
            fetchAll: true
          }
        });
        return response.data.users || [];
      },
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 60 * 8 // Cache for 8 hours
    });

    watch(clientsData, newVal => {
      if (newVal) {
        allClients.value = newVal;
        clientOptions.value = newVal.map(client => ({
          label: client.contact_email,
          value: client.client_id
        }));
      }
    });

    // Filtered options based on the search input
    const filteredClientOptions = computed(() => clientOptions.value);

    const handleClientSelect = client => {
      selectedClient.value = client;
      if (client) {
        filters.value.clientId = client.value;
      } else {
        delete filters.value.clientId;
      }
    };

    const filterFn = (val, update) => {
      if (val === '') {
        update(() => {
          clientOptions.value = allClients.value.map(client => ({
            label: client.company_name,
            value: client.client_id
          }));
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        clientOptions.value = allClients.value
          .filter(v => v.company_name.toLowerCase().indexOf(needle) > -1)
          .map(client => ({
            label: client.company_name,
            value: client.client_id
          }));
      });
    };

    const updateDebouncedVisual = debounce(value => {
      debouncedVisualQuery.value = value;
    }, 300);

    watch(visualSearchQuery, updateDebouncedVisual);

    const filters = computed(() => ({
      date: {
        from: dateRange.value.from,
        to: dateRange.value.to
      },
      services: selectedServices.value,
      aspectRatios: selectedAspectRatios.value,
      metadataSearchQuery: typeof metadataSearchQuery.value === 'string' ? metadataSearchQuery.value.trim() : '',
      visualSearchQuery: typeof debouncedVisualQuery.value === 'string' ? debouncedVisualQuery.value.trim() : '',
      clientId: selectedClient.value?.value || null
    }));

    const hasActiveFilters = computed(() => {
      return (
        dateRange.value.from !== null ||
        dateRange.value.to !== null ||
        selectedServices.value.length > 0 ||
        selectedAspectRatios.value.length > 0 ||
        (typeof metadataSearchQuery.value === 'string' && metadataSearchQuery.value.trim() !== '') ||
        (typeof visualSearchQuery.value === 'string' && visualSearchQuery.value.trim() !== '')
      );
    });

    const imagesQuery = useInfiniteQuery({
      queryKey: ['images', filters],
      queryFn: async ({ pageParam = null }) => {
        const payload = {
          filters: {
            ...(filters.value.date.from || filters.value.date.to ? { date: filters.value.date } : {}),
            ...(filters.value.services?.length ? { services: filters.value.services } : {}),
            ...(filters.value.aspectRatios?.length ? { aspectRatios: filters.value.aspectRatios } : {}),
            ...(filters.value.metadataSearchQuery ? { metadataSearchQuery: filters.value.metadataSearchQuery } : {}),
            ...(filters.value.visualSearchQuery ? { visualSearchQuery: filters.value.visualSearchQuery } : {}),
            ...(filters.value.clientId ? { clientId: filters.value.clientId } : {}) // Add clientId to the payload
          },
          pagination: {
            limit: 40,
            cursor: pageParam
          }
        };
        const response = await ShootsApi.getImages(payload);

        return response;
      },
      getNextPageParam: lastPage => {
        return lastPage.pagination.hasMore ? lastPage.pagination.nextCursor : undefined;
      },
      initialPageParam: null
    });

    const handleMetadataSearch = () => {
      imagesQuery.refetch();
    };

    const debouncedHandleMetadataSearch = debounce(handleMetadataSearch, 300);

    const currentPagination = computed(() => {
      if (!imagesQuery.data.value?.pages.length) return null;
      return imagesQuery.data.value.pages[imagesQuery.data.value.pages.length - 1].pagination;
    });

    const allImages = computed(() => {
      if (!imagesQuery.data.value) return [];
      return imagesQuery.data.value.pages.flatMap(page => page.images);
    });

    const shouldShowLoadingSpinner = computed(() => {
      return imagesQuery.isFetching.value && imagesQuery.hasNextPage.value && !imagesQuery.isLoading.value;
    });

    // Query for fetching integration status
    const { data: integrationStatus } = useQuery({
      queryKey: ['facebook-status'],
      queryFn: async () => {
        const response = await IntegrationsApi.getFacebookStatus();
        return response.data;
      },
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    const isMetaConnected = computed(() => integrationStatus.value?.connected || false);

    const metaIntegration = computed(() => integrationStatus.value?.integration || null);

    const handleDateRangeChange = range => {
      dateRange.value = range;
    };

    const handleServicesChange = services => {
      selectedServices.value = services;
    };

    const handleAspectRatioChange = ratios => {
      selectedAspectRatios.value = ratios;
    };

    const handleVisualSearch = query => {
      visualSearchQuery.value = query;
    };

    const clearFilters = () => {
      metadataSearchQuery.value = '';
      visualSearchQuery.value = '';
      dateRange.value = { from: null, to: null };
      selectedServices.value = [];
      selectedAspectRatios.value = [];
      selectedClient.value = null;
    };

    const handleScroll = debounce(async event => {
      const container = event.target;
      const scrollPosition = container.scrollTop + container.clientHeight;
      const scrollThreshold = container.scrollHeight - 200;

      if (scrollPosition >= scrollThreshold && !imagesQuery.isFetching.value && imagesQuery.hasNextPage.value) {
        await imagesQuery.fetchNextPage();
      }
    }, 100);

    return {
      scrollContainer,
      metadataSearchQuery,
      visualSearchQuery,
      dateRange,
      selectedServices,
      selectedAspectRatios,
      serviceOptions,
      aspectRatioOptions,
      imagesQuery,
      allImages,
      hasActiveFilters,
      shouldShowLoadingSpinner,
      handleDateRangeChange,
      handleServicesChange,
      handleAspectRatioChange,
      clearFilters,
      handleScroll,
      currentPagination,
      isMetaConnected,
      metaIntegration,
      selectedClient,
      clientOptions,
      filteredClientOptions,
      handleClientSelect,
      isLoadingClients,
      filterFn,
      handleVisualSearch,
      debouncedHandleMetadataSearch,
      user
    };
  }
};
</script>

<style lang="scss" scoped>
.gallery-container {
  @include inter-font($size: 12px, $color: var(--main-text-color));
  display: grid;
  grid-template-columns: 300px 1fr;
  height: calc(100vh - 76px);
  background: #fafafa;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.sidebar {
  background: white;
  border-right: 1px solid #eee;
  padding: 16px;
  overflow-y: auto;
  height: calc(100vh - 76px);
  @media (max-width: 1024px) {
    display: none;
  }
}

.gallery-content {
  overflow-y: scroll;
}

.full-height {
  height: 100%;
}

.q-date {
  min-width: unset !important;
  width: 100%;
  margin-bottom: 12px;
}

.checkbox {
  text-align: left;
  padding: 8px 16px;
}

.input-caption {
  text-align: left;
  @include inter-font($size: 11px, $color: var(--main-text-color));
  opacity: 0.55;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.info-icon {
  cursor: pointer;
  margin-left: auto;
}

.expansion-item-text {
  text-align: left;
  @include inter-font($size: 11px, $color: var(--main-text-color));
  opacity: 0.55;
  align-self: center;
}
</style>
