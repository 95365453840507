import { render, staticRenderFns } from "./ImageFormatDialog.vue?vue&type=template&id=3edc3ebb&scoped=true&"
import script from "./ImageFormatDialog.vue?vue&type=script&lang=js&"
export * from "./ImageFormatDialog.vue?vue&type=script&lang=js&"
import style0 from "./ImageFormatDialog.vue?vue&type=style&index=0&id=3edc3ebb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edc3ebb",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QBtn,QExpansionItem,QList,QItem,QItemSection,QItemLabel,QUploader,QIcon,QSlider,QSelect,QCheckbox,QCardActions});qInstall(component, 'directives', {ClosePopup,Ripple});
