<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <section class="cardShootDetail">
    <div class="cardShootDetail__img">
      <BaseImage class="cardShootDetail__image" :src="getServiceImage(serviceId)" />
      <div class="cardShootDetail__status">
        <div class="cardShootDetail__status-header">
          <BaseIcon fill="transparent" icon="status" stroke="#fff" :custom_style="{ height: '13px', 'stroke-width': '2px', width: '15px' }" />
          <span>{{ $t('STATUS') }}</span>
        </div>
        <div class="cardShootDetail__status-banner">
          <p :class="[{ 'cardShootDetail__status-banner--height': heightText }, { 'fs-40': phAssignText }]" v-html="statusText"></p>
          <div v-if="shoot.status === SCHEDULED && this.isAdmin" class="cardShootDetail__assign items-center">
            <BaseIcon class="mr-18" icon="camera" stroke="#fff" :custom_style="{ height: '17px', width: '17px' }" />
            <div class="cardShootDetail__select">
              <BaseSelect
                class="pa-0"
                option-label="name"
                option-value="photographer_id"
                use-input
                bgColor="white"
                :filterFn="filterOptions"
                :options="photographers"
                :value="photographer"
                @update:value="photographer = $event"
              />
            </div>
          </div>
        </div>
        <div v-if="showPhotographer" class="cardShootDetail__photographer row">
          <BaseIcon icon="camera" stroke="#fff" :custom_style="{ height: '17px', width: '17px' }" />
          <p class="ma-0 ml-15">
            {{ retrievePhName }} <br /><span>{{ getPhName() }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="cardShootDetail__info">
      <div class="cardShootDetail__shootName">{{ getName() }}</div>
      <CardShootInfo v-if="dateTime.date" icon="calendar" :shootId="shoot.id" :action="getAction" :subtitles="[dateTime.time]" :title="dateTime.date" />
      <CardShootInfo icon="map-pin" :title="shoot.address.formatted_address" :subtitles="locationSubtitles" />
      <CardShootInfo
        v-if="shoot.content === 'photography'"
        v-for="(pkg, index) of shoot.services"
        :key="index"
        :icon="(index === 0 && 'tag') || ''"
        :subtitles="getSubtitles(pkg)"
        :title="getService(pkg)"
      />
      <CardShootInfo
        v-if="shoot.content === 'videography'"
        v-for="(pkg, index) of shoot.services"
        :key="index + shoot.content"
        :icon="(index === 0 && 'tag') || ''"
        :subtitles="getVideoSubtitles()"
        :title="getService(pkg)"
      />
      <template v-if="isAdmin">
        <CardShootInfo icon="dollar-sign" :title="`${shoot.price} ${$t('AED')} ( ${$t('CUSTOMER_PRICE')})`" />
        <CardShootInfo icon="dollar-sign" :title="`${shoot.photographer_revenue} ${$t('AED')} ( ${shoot.content === 'photography' ? $t('PHOTOGRAPHER_PRICE') : $t('VIDEOGRAPHER_PRICE')})`" />
      </template>
      <CardShootInfo v-if="isClient && !isSubClient" icon="dollar-sign" :title="`${shoot.price} ${$t('AED')}`" />
      <CardShootInfo v-if="isPhotographer && !isPhotographerInternal" icon="dollar-sign" :title="`${$t('COMPENSATION')}: ${shoot.photographer_revenue} ${$t('AED')}`" />
      <div v-if="show_attachments && shoot.is_brief_uploaded" class="cardShootDetail__attach">
        <BaseIcon fill="transparent" icon="paperclip" stroke="#151515" :custom_style="{ height: '14px', 'stroke-width': '2px', width: '14px' }" />
        <span @click="downloadBrief">{{ $tc('SEE_ATTACHMENT') }}</span>
      </div>
      <div v-if="shoot.poc_name" class="cardShootDetail__poc">
        <p>POC details:</p>
        <p data-text-bolded="true">{{ shoot.poc_name }}</p>
        <p data-text-underline="true">{{ shoot.poc_email }}</p>
        <p>{{ shoot.poc_phone }}</p>
      </div>
      <div v-if="shoot.notes && !isEditor" class="cardShootDetail__attach">
        {{ shoot.notes }}
      </div>
    </div>
  </section>
</template>

<script>
// Api
import { ContentApi, UserApi } from '@api/index';
// Store
import { mapGetters } from 'vuex';

// Components
import CardShootInfo from '@/components/CardShootInfo.vue';

// Base Components
import BaseImage from '@base/BaseImage.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseSelect from '@base/BaseSelect.vue';

// Utils
import helpers, { ASSIGNED, CANCELED, COMPLETED, CONFIRMED, SCHEDULED, UPLOADED } from '@utils/shootsHelpers';

export default {
  name: 'CardShootDetail',
  components: {
    BaseImage,
    BaseIcon,
    BaseSelect,
    CardShootInfo
  },
  data() {
    return {
      SCHEDULED: SCHEDULED,
      photographer: null,
      photographers: [],
      photographersCopy: []
    };
  },
  props: {
    /**
     * The shoot we want to show the detail
     */
    shoot: { default: () => ({}), type: Object },
    /**
     * Use this prop to show the attachments section
     */
    show_attachments: { default: false, type: Boolean },
    /**
     * used to show the action on calendar
     */
    showCalendarDialog: { default: false, type: Boolean }
  },
  watch: {
    photographer() {
      this.$emit('update:photograper', this.photographer);
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isEditor: 'user/isEditor',
      isPhotographer: 'user/isPhotographer',
      isPhotographerInternal: 'user/isPhotographerInternal',
      isSubClient: 'user/isSubClient',
      services: 'services/getServices'
    }),
    /**
     * Returning an object with the shoot date and time formatted for FE
     */
    dateTime() {
      if (!this.shoot.datetime) return {};
      return this.fromDateTimeToDateAndTimeRange({ from: this.shoot.datetime, duration: this.shoot.duration });
    },
    getAction() {
      if (!this.showCalendarDialog) return this.$t('SEE_CALENDAR');
      return '';
    },
    /**
     * used to set the text height of status banners based to the shooting status
     */
    heightText() {
      return (
        (this.isAdmin && this.shoot.status === CONFIRMED) ||
        (this.isClient && this.shoot.status === CONFIRMED) ||
        (this.isClient && this.shoot.status === UPLOADED) ||
        (this.isClient && this.shoot.status === SCHEDULED) ||
        (this.isPhotographer && this.shoot.status === CONFIRMED)
      );
    },
    /**
     * used to set the text height of status banners based to the shooting status
     */
    phAssignText() {
      return this.isPhotographer && this.shoot.status === ASSIGNED;
    },
    /**
     * Retrieve subtitles displayed under the location
     */
    locationSubtitles() {
      const subtitles = [];

      if (this.isAdmin || this.isPhotographer) subtitles.push(this.$t('PACKAGE_CUSTOMER', { customer: this.shoot.customer_name || '' }));

      return subtitles;
    },
    service() {
      return this.shoot.services[0] || {};
    },
    serviceId() {
      if (this.shoot.services.length > 1 || this.shoot.services.length === 0) {
        return;
      }

      return this.service.service_id;
    },
    /**
     * Used to check if the photographer name exists and if it should be displayed
     **/
    showPhotographer() {
      return (
        (this.shoot.status === CONFIRMED || this.shoot.status === UPLOADED || this.shoot.status === CANCELED || this.shoot.status === COMPLETED) && (this.isClient || this.isAdmin) && this.getPhName()
      );
    },
    /**
     * Retrieve the status text based on the shoot status
     */
    statusText() {
      return helpers.retrieveStatusBanner({
        isAdmin: this.isAdmin,
        isClient: this.isClient,
        isPhotographer: this.isPhotographer,
        status: this.shoot.status,
        name: this.shoot.photographer_name,
        isVideo: this.shoot.content === 'videography',
        isRawsPreviewEnabled: this.shoot.raws_preview,
        isPreviewEnabled: this.shoot.images_preview
      });
    },
    /**
     * used for set the first part of text for photograper's name.
     */
    retrievePhName() {
      if (this.isClient) return this.$t('YOUR_PH_IS');
      if (this.shoot.status === CANCELED || this.shoot.status === COMPLETED)
        return this.shoot.content === 'photography' ? this.$t('PAST_PHOTOGRAPHER_CONFIRMED') : this.$t('PAST_VIDEOGRAPHER_CONFIRMED');
      return this.shoot.content === 'photography' ? this.$t('PHOTOGRAPHER_CONFIRMED') : this.$t('VIDEOGRAPHER_CONFIRMED');
    }
  },
  async created() {
    if (this.shoot.status === SCHEDULED && this.isAdmin) {
      const photographers = await UserApi.searchUsers({ payload: { filters: { role: 'photographer' } } });
      this.photographers = photographers.data.users.filter(({ status }) => status === 'active');
      this.photographersCopy = photographers.data.users.filter(({ status }) => status === 'active');
    }
  },
  methods: {
    /**
     * Method used to download brief
     */
    async downloadBrief() {
      const id = this.shoot.redeemable_shoot_id || this.shoot.id;
      await ContentApi.downloadBrief({ id });
    },
    /**
     * Filter photographers when the user starts to type
     */
    filterOptions(value, update) {
      if (value === '') {
        update(() => {
          this.photographers = this.photographersCopy;
        });
        return;
      }

      update(() => {
        const needle = value.toLowerCase();
        this.photographers = this.photographersCopy.filter(v => v.name.toLowerCase().indexOf(needle) > -1 || `${v.photographer_id}` === `${needle}`);
      });
    },
    /**
     * Retrieve name of the shoot
     */
    getName() {
      return this.shoot.name || this.$t('PACKAGE_NAME_TITLE');
    },
    /**
     * Retrieve name and surname of the photographer of the shoot
     */
    getPhName() {
      const text = this.shoot.photographer_name;

      if (!text) return '';

      const splits = text.split(' ');

      if (splits.length === 1) return splits[0];

      const surname = [...splits].reverse()[0] || '';

      const names = [...splits].reverse().slice(1) || [];
      const name = names.reverse().join(' ') || '';

      return `${name} ${surname.charAt(0)}.`;
    },
    /**
     * Retrieve service name based on id
     */
    getService(service) {
      const _service = this.services.find(serviceItem => serviceItem.id === service.service_id) || {};
      return _service.name;
    },
    /**
     * Retrieve subtitles based on service
     */
    getSubtitles(service) {
      const subtitles = [];

      subtitles.push(this.$t('PACKAGE_NAME', { name: service.package.name }));
      subtitles.push(this.$tc('PICTURE', service.picture_number));
      return subtitles;
    },
    getVideoSubtitles() {
      const subtitles = [];
      subtitles.push(this.$t('Video quantity: ' + this.shoot.video_number));
      subtitles.push(this.$t('Duration per video: ' + this.shoot.video_duration + ' seconds'));
      return subtitles;
    },
    inlineBgImage(src) {
      if (!src) return;
      let bgImage = require('@/assets' + src);

      return bgImage;
    }
  }
};
</script>

<style lang="scss" scoped>
.cardShootDetail {
  display: flex;
  max-height: 100%;

  &__assign {
    display: flex;
  }

  &__attach {
    align-items: center;
    background-color: var(--attach-bg-color);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-top: 36px;
    padding: 6px;
    white-space: pre-line;

    span {
      @include inter-font($size: 12px, $color: var(--main-text-color));
      font-style: italic;
      margin-left: 13px;
      text-decoration: underline;
    }
  }

  &__img {
    border-radius: 5px;
    height: 340px;
    overflow: hidden;
    position: relative;
    max-width: 280px;
    min-width: 280px;
    width: 45%;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    opacity: 0.85;
    width: 100%;
  }

  &__info {
    margin-left: 47px;
    overflow-y: auto;
    padding-right: 35px;

    .cardShootInfo ~ .cardShootInfo {
      margin-top: 19px;
    }
  }

  &__photographer {
    > p {
      @include antonio-font($size: 14px, $uppercase: false, $color: var(--terziary-text-color));
      > span {
        @include antonio-font($size: 20px, $uppercase: true, $color: var(--terziary-text-color));
        font-weight: 700;
      }
    }
  }

  &__select {
    max-width: 12.5rem;
  }
  &__shootName {
    @include antonio-font($size: 18px, $uppercase: true, $color: var(--main-text-color));
    padding-bottom: 20px;
  }

  &__status {
    background-color: var(--card-status-background);
    display: grid;
    grid-template-rows: 1fr 8fr 1fr;
    height: 100%;
    left: 0;
    padding: 21px;
    position: absolute;
    top: 0;
    width: 100%;

    &-header {
      @include inter-font($size: 14px, $color: var(--status-text-color));

      span {
        font-weight: bold;
        margin-left: 8px;
      }
    }
  }

  &__status-banner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;

    > p {
      @include antonio-font($size: 20px, $color: var(--terziary-text-color));
      margin-bottom: 0;
      padding-left: 20px;
      white-space: pre-wrap;
    }
    &--height {
      font-size: 38px !important;
      line-height: 1.184;
      width: 200px;
    }
    .fs-16 {
      text-transform: lowercase;
    }
  }

  &__poc {
    color: gray;
    margin-top: 2em;

    > p {
      margin: 0;
      &:first-child {
        margin-bottom: 0.5em;
      }

      &[data-text-bolded='true'] {
        font-weight: bold;
      }

      &[data-text-underline='true'] {
        text-decoration: underline;
      }
    }
  }
}

@include responsive($min: md, $max: tablet) {
  .cardShootDetail {
    &__attach {
      margin-top: 26px;

      span {
        font-size: calculateRem(10px);
        margin-left: 8px;
      }
    }

    &__info {
      margin-left: 20px;

      .cardShootInfo ~ .cardShootInfo {
        margin-top: 20px;
      }
    }
  }
}

@include responsive($max: md) {
  .cardShootDetail {
    display: flex;
    flex-direction: column;
    &__assign {
      > svg {
        margin-right: 8px;
      }
    }
    &__attach {
      margin-top: 15px;
    }
    &__img {
      height: 209px;
      min-height: 209px;
      max-width: unset;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        opacity: 0.85;
        width: 100%;
      }
    }

    &__info {
      margin-left: 10px;
      margin-top: 20px;

      .cardShootInfo ~ .cardShootInfo {
        margin-top: 15px;
      }
    }

    &__status {
      left: 0;
      padding: 21px;
      position: absolute;
      top: 0;
      &-banner {
        margin-top: 5px;
        &--height {
          font-size: 28px !important;
          width: 240px;
        }
      }

      &-header {
        @include inter-font($size: 14px, $color: var(--status-text-color));
        span {
          margin-left: 8px;
        }
      }
    }
    &__select {
      max-width: none;
      width: calc(100vw - 8.3125rem);
    }
    &__status-banner,
    &__assign {
      max-width: 280px;
      width: auto;
    }
  }
}

@include responsive($max: mobileL) {
  .cardShootDetail {
    &__select {
      width: calc(100vw - 5.6875rem);
    }
  }
}
</style>
