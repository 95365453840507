<template>
  <q-dialog v-model="isOpen" maximized transition-show="fade" transition-hide="fade" @hide="$emit('close')">
    <div class="gallery-lightbox">
      <div class="lightbox-content">
        <transition name="fade" mode="out-in">
          <div :key="currentIndex" class="image-container">
            <img :src="currentImage.placeholder_url" :style="imageStyle" class="placeholder-image" />
            <img :src="currentImage.url" :style="imageStyle" class="full-image" @load="onImageLoad" :class="{ loaded: imageLoaded }" />
          </div>
        </transition>
        <q-btn round color="grey-10" text-color="grey-6" icon="close" class="close-btn" @click="$emit('close')" />
        <q-btn round color="grey-10" text-color="grey-6" icon="chevron_left" class="nav-btn prev-btn" @click="prevImage" :disable="currentIndex === 0" />
        <q-btn round color="grey-10" text-color="grey-6" icon="chevron_right" class="nav-btn next-btn" @click="nextImage" :disable="currentIndex === images.length - 1" />
      </div>
      <div class="thumbnail-container">
        <q-scroll-area style="height: 100px; width: 100%">
          <div class="row no-wrap q-pa-sm">
            <div v-for="(image, index) in images" :key="index" class="thumbnail-wrapper q-mr-sm" :class="{ 'thumbnail-active': index === currentIndex }" @click="selectImage(index)">
              <q-img :src="image.preview_url" :src-placeholder="image.placeholder_url" :ratio="image.aspect_ratio" class="thumbnail" />
            </div>
          </div>
        </q-scroll-area>
      </div>
    </div>
  </q-dialog>
</template>

<script>
export default {
  name: 'GalleryLightbox',
  props: {
    images: {
      type: Array,
      required: true
    },
    startIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isOpen: true,
      currentIndex: this.startIndex,
      imageLoaded: false
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
    imageStyle() {
      const { aspect_ratio } = this.currentImage;
      let style = {};

      if (aspect_ratio > 1) {
        // Landscape
        style.width = '100%';
        style.height = 'auto';
      } else if (aspect_ratio < 1) {
        // Portrait
        style.width = 'auto';
        style.height = '100%';
      } else {
        // Square
        style.width = '100%';
        style.height = '100%';
        style.objectFit = 'contain';
      }

      return style;
    }
  },
  methods: {
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.imageLoaded = false;
      }
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
        this.imageLoaded = false;
      }
    },
    onImageLoad() {
      this.imageLoaded = true;
    },
    selectImage(index) {
      this.currentIndex = index;
      this.imageLoaded = false;
    },
    handleKeyboardNavigation(event) {
      if (event.key === 'ArrowLeft') {
        this.prevImage();
      } else if (event.key === 'ArrowRight') {
        this.nextImage();
      }
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        document.addEventListener('keydown', this.handleKeyboardNavigation);
      } else {
        document.removeEventListener('keydown', this.handleKeyboardNavigation);
      }
    }
  },
  mounted() {
    if (this.isOpen) {
      document.addEventListener('keydown', this.handleKeyboardNavigation);
    }
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyboardNavigation);
  }
};
</script>

<style scoped>
.gallery-lightbox {
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.lightbox-content {
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.placeholder-image,
.full-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  margin: auto;
}

.placeholder-image {
  filter: blur(10px);
  transition: opacity 0.3s ease-out;
}

.full-image {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.full-image.loaded {
  opacity: 1;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev-btn {
  left: 20px;
}

.next-btn {
  right: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.thumbnail-container {
  width: 100%;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
}

.thumbnail-wrapper {
  width: 80px;
  height: 80px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.thumbnail-wrapper:hover {
  border-color: rgba(255, 255, 255, 0.5);
}

.thumbnail-active {
  border-color: #fff;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
