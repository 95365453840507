// store/modules/downloads.js
import { ContentApi } from '@api/index';
import Vue from 'vue';

const state = {
  downloads: {},
  activeDownloads: 0,
  maxConcurrentDownloads: 3
};

const getters = {
  getDownloadById: state => id => state.downloads[id],
  getAllDownloads: state => Object.values(state.downloads),
  getPendingDownloads: state => Object.values(state.downloads).filter(d => d.status === 'pending'),
  getActiveDownloads: state => Object.values(state.downloads).filter(d => d.status === 'processing'),
  hasActiveDownloads: state => Object.keys(state.downloads).length > 0
};

const mutations = {
  ADD_DOWNLOAD(state, { id, download }) {
    Vue.set(state.downloads, id, download);
  },
  UPDATE_DOWNLOAD(state, { id, data }) {
    if (state.downloads[id]) {
      Vue.set(state.downloads, id, { ...state.downloads[id], ...data });
    }
  },
  REMOVE_DOWNLOAD(state, id) {
    Vue.delete(state.downloads, id);
  },
  SET_ACTIVE_DOWNLOADS(state, count) {
    state.activeDownloads = count;
  }
};

const actions = {
  addDownload({ commit, dispatch }, { shootId, shootName }) {
    const downloadId = Date.now().toString(36) + Math.random().toString(36).substr(2);

    commit('ADD_DOWNLOAD', {
      id: downloadId,
      download: {
        id: downloadId,
        shootId,
        shootName,
        status: 'pending',
        progress: 0,
        message: '',
        downloadUrl: null,
        createdAt: new Date(),
        error: null
      }
    });

    dispatch('processQueue');
    return downloadId;
  },

  async processQueue({ state, getters, commit, dispatch }) {
    if (state.activeDownloads >= state.maxConcurrentDownloads) return;

    const pendingDownloads = getters.getPendingDownloads;
    if (pendingDownloads.length === 0) return;

    const nextDownload = pendingDownloads[0];
    commit('SET_ACTIVE_DOWNLOADS', state.activeDownloads + 1);

    commit('UPDATE_DOWNLOAD', {
      id: nextDownload.id,
      data: {
        status: 'processing',
        message: 'Starting download...'
      }
    });

    try {
      await dispatch('startDownloadProcess', nextDownload);
    } catch (error) {
      commit('UPDATE_DOWNLOAD', {
        id: nextDownload.id,
        data: {
          status: 'error',
          error: error.message
        }
      });
    } finally {
      commit('SET_ACTIVE_DOWNLOADS', state.activeDownloads - 1);
      dispatch('processQueue');
    }
  },

  async startDownloadProcess({ commit }, download) {
    const pollInterval = 3000;
    let attempts = 0;
    const maxAttempts = 100; // 5 minutes timeout

    while (attempts < maxAttempts) {
      try {
        const response = await ContentApi.getDownloadJobStatus({
          id: download.shootId
        });

        commit('UPDATE_DOWNLOAD', {
          id: download.id,
          data: {
            progress: response.progress || 0,
            message: response.message,
            downloadUrl: response.downloadUrl,
            status: response.status
          }
        });

        if (response.status === 'completed' || response.status === 'error') {
          break;
        }

        await new Promise(resolve => setTimeout(resolve, pollInterval));
        attempts++;
      } catch (error) {
        throw new Error('Failed to fetch download status');
      }
    }

    if (attempts >= maxAttempts) {
      throw new Error('Download timed out');
    }
  },

  removeDownload({ commit }, downloadId) {
    commit('REMOVE_DOWNLOAD', downloadId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
