<template>
  <div class="integration-card">
    <div class="card-header">
      <div class="platform-info">
        <div class="platform-icon" :class="platform.toLowerCase()">
          <i :class="iconClass"></i>
        </div>
        <div>
          <p class="title">{{ title }}</p>
          <p class="sub-title">{{ subtitle }}</p>
        </div>
      </div>
      <div class="connection-status" :class="{ connected: isConnected }">
        <span class="status-dot"></span>
        <span>{{ isConnected ? 'Connected' : 'Not Connected' }}</span>
      </div>
    </div>

    <div class="card-body">
      <!-- Use Cases Section -->
      <div class="use-cases">
        <div class="use-case-grid">
          <div v-for="(feature, index) in features" :key="index" class="use-case-card">
            <div class="use-case-icon" :class="getUseCaseClass(index)">
              <i :class="getUseCaseIcon(index)"></i>
            </div>
            <div class="use-case-content">
              <h5>{{ feature.title }}</h5>
              <p>{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button class="connection-button" :class="{ disconnect: isConnected }" @click="handleConnection">
        {{ isConnected ? `Disconnect ${platform}` : `Connect ${platform}` }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegrationCard',

  props: {
    platform: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },

    features: {
      type: Array,
      required: true
    },
    isConnected: {
      type: Boolean,
      default: false
    },
    connectedAssets: {
      type: Object,
      default: () => ({}),
      required: false
    }
  },

  methods: {
    handleConnection() {
      this.$emit('connection-click');
    },
    getUseCaseClass(index) {
      const classes = ['publishing', 'upload', 'analytics']; // Changed for Facebook
      const instagramClasses = ['sync', 'caption', 'story']; // Changed for Instagram
      return this.platform === 'Facebook' ? classes[index] : instagramClasses[index];
    },
    getUseCaseIcon(index) {
      const facebookIcons = {
        publishing: 'far fa-clock', // Outlined clock for scheduling
        upload: 'far fa-images', // Outlined images for bulk upload
        analytics: 'far fa-chart-bar' // Outlined chart for analytics
      };

      const instagramIcons = {
        sync: 'far fa-sync', // Outlined sync icon
        caption: 'far fa-comment-dots', // Outlined comment for captions
        story: 'far fa-stories' // Outlined stories icon
      };

      const classes = this.platform === 'Facebook' ? ['publishing', 'upload', 'analytics'] : ['sync', 'caption', 'story'];

      return this.platform === 'Facebook' ? facebookIcons[classes[index]] : instagramIcons[classes[index]];
    }
  },

  computed: {
    iconClass() {
      const icons = {
        Facebook: 'fab fa-facebook-f',
        Instagram: 'fab fa-instagram'
      };
      return icons[this.platform] || 'fas fa-plug';
    }
  }
};
</script>

<style lang="scss" scoped>
.integration-card {
  text-align: left;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 24px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}

.platform-info {
  display: flex;
  align-items: center;
  gap: 16px;

  .title {
    @include inter-font($size: 18px, $bolded: false, $color: var(--main-text-color));
    margin: 0;
  }

  .sub-title {
    @include inter-font($size: 12px, $bolded: false, $color: var(--main-text-color));
    color: #86868b;
    margin: 0;
  }
}

.platform-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &.facebook {
    background: #f0f2ff;
    color: #1877f2;
  }

  &.instagram {
    background: #fef0f0;
    color: #e4405f;
  }

  &.canva {
    background: #f6f0ff;
    color: #7d2ae8;
  }
}

.connection-status {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 20px;
  background: #f5f5f7;
  font-size: 13px;
  font-weight: 500;
  color: #86868b;

  .status-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #86868b;
  }

  &.connected {
    background: #e3f9e5;
    color: #34c759;

    .status-dot {
      background: #34c759;
    }
  }
}

.card-body {
  margin-bottom: 24px;
}

.use-cases {
  margin-top: 32px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1d;
    margin: 0 0 16px 0;
  }
}

.use-case-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.use-case-card {
  background: #f8f8f8;
  border-radius: 12px;
  padding: 16px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background: #f0f0f0;
  }
}

.use-case-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  font-size: 16px;

  &.publishing {
    background: #e3f2fd;
    color: #1976d2;
  }

  &.upload {
    background: #e8f5e9;
    color: #2e7d32;
  }

  &.analytics {
    background: #fff3e0;
    color: #f57c00;
  }

  &.sync {
    background: #f3e5f5;
    color: #7b1fa2;
  }

  &.caption {
    background: #e8eaf6;
    color: #3f51b5;
  }

  &.story {
    background: #fce4ec;
    color: #c2185b;
  }
}

.use-case-content {
  h5 {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1d;
    margin: 0 0 4px 0;
  }

  p {
    font-size: 12px;
    color: #6b6b6b;
    margin: 0;
    line-height: 1.4;
  }
}

.card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 24px;
}

.connected-account {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  padding: 8px 12px;
  background: #f5f5f7;
  border-radius: 12px;

  .account-avatar {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #86868b;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #1c1c1e;
  }
}

.connection-button {
  width: 100%;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #007aff;
  color: white;

  &:hover {
    background: #0066d6;
  }

  &.disconnect {
    background: #f5f5f7;
    color: #1c1c1e;

    &:hover {
      background: #e5e5ea;
    }
  }
}
</style>
