<template>
  <div class="integrations-container q-pa-md">
    <div class="header-section">
      <p class="main-header">Platform Integrations</p>
      <p class="description">
        Amplify your creative work by connecting to leading social platforms. Showcase your photography and videography directly to your clients' channels while maintaining perfect quality and
        formatting. Schedule content releases, manage multi-platform campaigns, and help your clients maintain a consistent brand presence.
      </p>
    </div>

    <div class="integrations-list">
      <integration-card
        platform="Facebook"
        title="Facebook Business"
        subtitle="Professional content publishing platform"
        :features="[
          {
            title: 'Business Integration',
            description: 'Connect and manage multiple business pages and assets'
          },
          {
            title: 'Automated Publishing',
            description: 'Set up automated post scheduling and cross-platform content distribution'
          },
          {
            title: 'Analytics Export',
            description: 'Export engagement metrics and audience insights to CSV reports'
          }
        ]"
        :is-connected="isConnected"
        :connected-assets="integrationData || {}"
        @connection-click="handleFacebookConnection"
        class="q-mb-lg"
      />
    </div>

    <q-dialog v-model="state.confirmDisconnect.show" persistent>
      <q-card style="min-width: 400px">
        <q-card-section class="row items-center">
          <q-avatar size="40px" class="q-mr-sm">
            <q-icon name="warning" color="warning" />
          </q-avatar>
          <span class="text-h6">Disconnect {{ state.confirmDisconnect.platform }}?</span>
        </q-card-section>

        <q-card-section class="text-body1">
          This will remove the connection to your {{ state.confirmDisconnect.platform }} account. Any automated sharing settings will need to be reconfigured.
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-primary">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="Disconnect" color="negative" @click="handleDisconnectConfirm" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="state.connectionStatus.show">
      <q-card style="min-width: 400px">
        <q-card-section class="row items-center">
          <q-avatar size="40px" class="q-mr-sm">
            <q-icon :name="state.connectionStatus.icon" :color="state.connectionStatus.color" />
          </q-avatar>
          <span class="text-h6">{{ state.connectionStatus.title }}</span>
        </q-card-section>

        <q-card-section class="text-body1">
          {{ state.connectionStatus.message }}
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-primary">
          <q-btn flat label="Close" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { reactive, computed, watch } from '@vue/composition-api';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import IntegrationCard from '@/components/IntegrationCard.vue';
import { IntegrationsApi } from '@/api/index';

export default {
  name: 'IntegrationsView',

  components: {
    IntegrationCard
  },

  setup() {
    const queryClient = useQueryClient();
    const FB_CONFIG_ID = `${process.env.VUE_APP_FB_CONFIG_ID}`;

    const state = reactive({
      confirmDisconnect: {
        show: false,
        platform: '',
        action: null
      },
      connectionStatus: {
        show: false,
        title: '',
        message: '',
        icon: '',
        color: ''
      },
      isFBLoaded: false
    });

    // Query for fetching integration status
    const { data: integrationStatus, isLoading: isLoadingStatus } = useQuery({
      queryKey: ['facebook-status'],
      queryFn: async () => {
        const response = await IntegrationsApi.getFacebookStatus();
        return response.data;
      },
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    // Update computed properties to match the actual data structure
    const isConnected = computed(() => integrationStatus.value?.connected || false);

    const integrationData = computed(() => integrationStatus.value?.integration || null);

    // Connect mutation should also follow the same structure
    const connectMutation = useMutation({
      mutationFn: code => IntegrationsApi.connectFacebook(code),
      onSuccess: response => {
        queryClient.setQueryData(['facebook-status'], {
          connected: true,
          integration: response.data
        });
        showConnectionStatus('success', 'Connection Successful', 'Successfully connected to Facebook Business');
      },
      onError: error => {
        showConnectionStatus('error', 'Connection Failed', error.message || 'Failed to connect Facebook Business account');
      }
    });

    // Disconnect mutation should match too
    const disconnectMutation = useMutation({
      mutationFn: () => IntegrationsApi.disconnectFacebook(),
      onSuccess: () => {
        queryClient.setQueryData(['facebook-status'], {
          connected: false,
          reason: 'DISCONNECTED'
        });
        showConnectionStatus('success', 'Disconnection Successful', 'Successfully disconnected Facebook Business account');
      }
    });

    // Helper functions
    const showConnectionStatus = (type, title, message) => {
      state.connectionStatus = {
        show: true,
        title,
        message,
        icon: type === 'success' ? 'check_circle' : type === 'error' ? 'error' : 'info',
        color: type === 'success' ? 'positive' : type === 'error' ? 'negative' : 'info'
      };
    };

    const loadFacebookSDK = () => {
      return new Promise(resolve => {
        if (!document.getElementById('facebook-jssdk')) {
          window.fbAsyncInit = () => {
            window.FB.init({
              appId: `${process.env.VUE_APP_FB_CLIENT_ID}`,
              cookie: true,
              xfbml: true,
              version: 'v21.0'
            });
            state.isFBLoaded = true;
            resolve();
          };
          const script = document.createElement('script');
          script.id = 'facebook-jssdk';
          script.src = 'https://connect.facebook.net/en_US/sdk.js';
          script.async = true;
          script.defer = true;
          document.head.appendChild(script);
        } else if (window.FB) {
          state.isFBLoaded = true;
          resolve();
        }
      });
    };

    const handleFacebookLogin = async () => {
      if (!state.isFBLoaded) {
        await loadFacebookSDK();
      }
      window.FB.login(
        async response => {
          if (response.authResponse?.code) {
            try {
              await connectMutation.mutateAsync(response.authResponse.code);
            } catch (error) {
              console.error('Failed to connect Facebook:', error);
            }
          }
        },
        {
          config_id: FB_CONFIG_ID,
          response_type: 'code',
          override_default_response_type: true,
          auth_type: 'rerequest',
          scope: 'instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement,pages_manage_posts,business_management'
        }
      );
    };

    // Main handlers
    const handleFacebookConnection = async () => {
      if (isConnected.value) {
        state.confirmDisconnect = {
          show: true,
          platform: 'Facebook',
          action: async () => {
            try {
              await disconnectMutation.mutateAsync();
            } catch (error) {
              console.error('Failed to disconnect Facebook:', error);
              showConnectionStatus('error', 'Disconnection Failed', 'Failed to disconnect Facebook Business account');
            }
          }
        };
      } else {
        try {
          if (!state.isFBLoaded) {
            await loadFacebookSDK();
          }
          if (!window.FB) {
            throw new Error('Facebook SDK not loaded');
          }

          await handleFacebookLogin();
        } catch (error) {
          console.error('Error initializing Facebook auth:', error);
          showConnectionStatus('error', 'Connection Failed', 'Failed to initialize Facebook Business login');
        }
      }
    };

    const handleDisconnectConfirm = async () => {
      if (state.confirmDisconnect.action) {
        await state.confirmDisconnect.action();
      }
      state.confirmDisconnect = {
        show: false,
        platform: '',
        action: null
      };
    };

    // Debug logs
    watch(integrationStatus, newStatus => {
      console.log('Integration status updated:', newStatus);
    });

    // Return composable values and functions
    return {
      state,
      isConnected,
      integrationData,
      isLoadingStatus,
      handleFacebookConnection,
      handleDisconnectConfirm
    };
  }
};
</script>

<style lang="scss" scoped>
.integrations-container {
  max-width: 800px;
  margin: 0 auto;
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
}

.header-section {
  text-align: left;
  flex-shrink: 0;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .main-header {
    @include inter-font($size: 38px);
    color: #1d1d1d;
    font-weight: 600;
    margin: 0 0 8px 0;
    letter-spacing: -0.5px;
  }

  .description {
    @include inter-font($size: 14px);
    color: #6b6b6b;
    line-height: 1.6;
    margin: 0;
    max-width: 720px;
  }
}

.integrations-list {
  flex: 1;
  overflow-y: auto;
}
</style>
